import lambdaApi from 'api/LambdaApi';
import { SkillsSkillModelList } from 'generated-types/skills/skill';
import { useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export type UseSkillsType = {
    label: string;
    value: string;
}[];

export const useSkillsHook = (isExternal?: boolean) => {
    return ({ enabled }: { enabled: boolean }) => {
        const getUri = isExternal ? `${skillsDBPrefix}/ext/skills` : `${skillsDBPrefix}/skills`;

        const { data, isLoading, refetch } = useQuery<SkillsSkillModelList>(
            ['skills'],
            () => lambdaApi.get(getUri, {}).then(res => res.data),
            {
                retry: false,
                enabled,
            },
        );

        let result: UseSkillsType = [];

        if (data) {
            result = data
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(skill => ({
                    label: skill.name,
                    value: `skill-${skill.id}`,
                }));
        }

        return {
            isLoading,
            skills: result,
            refetch,
        };
    };
};
