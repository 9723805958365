import { Document, Font } from '@react-pdf/renderer';
import format from 'date-fns/format';
import { SkillsMemberCertificationModelList } from 'generated-types/skills/certification';
import { SkillsEducationModelList } from 'generated-types/skills/education';
import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { SkillsMemberModel } from 'generated-types/skills/member';
import { SkillsMemberSkillModelList } from 'generated-types/skills/skill';

// @ts-ignore @eslint-ignore
import montserratBold from '../../../../assets/fonts/Montserrat-Bold.ttf';
// @ts-ignore @eslint-ignore
import montserratRegular from '../../../../assets/fonts/Montserrat-Regular.ttf';
// @ts-ignore @eslint-ignore
import openSansLight from '../../../../assets/fonts/OpenSans-Light.ttf';
// @ts-ignore @eslint-ignore
import openSansRegular from '../../../../assets/fonts/OpenSans-Regular.ttf';
import { ClosingPage } from './ClosingPage';
import { EducationPage } from './EducationPage';
import { ExperiencePage } from './ExperiencePage';
import { IntroductionPage } from './IntroductionPage';
import { SkillsPage } from './SkillsPage';

export const PdfCV = ({
    member,
    skills,
    certifications,
    otherExperiences,
    circlesExperiences,
    educations,
    externalPicture,
}: {
    member: SkillsMemberModel;
    skills: SkillsMemberSkillModelList;
    certifications: SkillsMemberCertificationModelList;
    otherExperiences: SkillsExperienceModelList;
    circlesExperiences: SkillsExperienceModelList;
    educations: SkillsEducationModelList;
    externalPicture?: string;
}) => {
    const companyName = 'Circles Consulting';
    const footerText = `${member.firstname} ${member.lastname} - ${companyName} - ${format(
        new Date(),
        'dd.MM.yyyy',
    )} | `;

    Font.register({
        family: 'Montserrat',
        fonts: [{ src: montserratRegular }, { src: montserratBold, fontWeight: 'bold' }],
    });
    Font.register({
        family: 'OpenSans',
        fonts: [
            {
                src: openSansRegular,
            },
            {
                src: openSansLight,
                fontWeight: 'light',
                fontStyle: 'normal',
            },
        ],
    });

    const renderSkillsPage = skills.some(
        skill => skill?.level && skill.level > 0 && skill.show_on_cv !== false,
    );
    const renderCircleExperiencePage = circlesExperiences.length > 0;
    const renderOtherExperiencePage = otherExperiences.length > 0;
    const renderEducationPage = educations.length > 0 || certifications.length > 0;

    return (
        <Document
            title={`CV ${member.firstname} ${member.lastname}`}
            author={`${member.firstname} ${member.lastname}`}
            creator={companyName}
            producer="my.circles.fi"
        >
            <IntroductionPage member={member} externalPicture={externalPicture} />
            {renderSkillsPage && <SkillsPage skills={skills} footerText={footerText} />}
            {renderCircleExperiencePage && (
                <ExperiencePage
                    experiences={circlesExperiences}
                    footerText={footerText}
                    pageTitle={`Experience at ${companyName}`}
                />
            )}
            {renderOtherExperiencePage && (
                <ExperiencePage
                    experiences={otherExperiences}
                    footerText={footerText}
                    pageTitle="Work Experience"
                />
            )}
            {renderEducationPage && (
                <EducationPage
                    educations={educations}
                    footerText={footerText}
                    certifications={certifications}
                />
            )}
            <ClosingPage />
        </Document>
    );
};
