import { showErrorNotification, showNotification } from 'components/ShowNotification';
import {
    SkillsCertificationBaseModel,
    SkillsMemberCertificationModelList,
} from 'generated-types/skills/certification';
import { useCertificationsHook } from 'hooks/skills/useCertifications';
import { useMemberCertificationsHook } from 'hooks/skills/useMemberCertifications';
import { useProfile, useMyExternalProfile } from 'hooks/useProfile';
import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, FlexboxGrid, Input } from 'rsuite';
import { isExternalRouter } from 'auth/cognito';

import AddCertification from './AddCertification';
import { CertificationSelect } from './CertificationSelect';
import { extSkillsIdAddition } from '.';

export const CertificationsSelect = ({
    memberCertifications,
    setSelectMode,
}: {
    memberCertifications: SkillsMemberCertificationModelList;
    setSelectMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [search, setSearch] = useState('');
    const hookToUseForProfile = isExternalRouter ? useMyExternalProfile : useProfile;
    const { user } = hookToUseForProfile();
    const [showSelectedOnly, setShowSelectedOnly] = useState(false);
    const useCertifications = useCertificationsHook(isExternalRouter);
    const {
        certifications,
        addCertification,
        deleteCertification,
        refetch: refetchCerts,
    } = useCertifications({
        enabled: true,
        member_id: user?.id,
    });
    const [filteredCertifications, setFilteredCertifications] = useState(certifications);
    const [selectedCertifications, setSelectedCertifications] = useState<
        SkillsCertificationBaseModel[]
    >(
        memberCertifications.map(certification => {
            return {
                id: certification.id,
                validation: certification?.validation,
            };
        }),
    );

    const deleteAndRefetch = async (id: number) => {
        deleteCertification.mutate(
            { id },
            {
                onSuccess: () => {
                    showNotification({ header: 'Certification deleted sucessfully.' });
                    refetchCerts();
                },
            },
        );
    };

    const addNewCert = (issuer: string, name: string) => {
        addCertification.mutate(
            {
                issuer,
                name,
                badge_image_url: '',
            },
            {
                onSuccess: async data => {
                    showNotification({ header: 'Certificate added successfully.' });
                    await refetchCerts();
                    const newCertId = data.id;

                    changeSelection(newCertId, true);
                },
                onError: err => {
                    showErrorNotification(err);
                },
            },
        );
    };

    const useMemberCertifications = useMemberCertificationsHook(isExternalRouter);
    const { updateCertifications, refetch } = useMemberCertifications({
        enabled: false,
        member_id: user?.id,
    });

    const onSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        updateCertifications.mutate(
            {
                selected_certifications: selectedCertifications.map(c => {
                    return {
                        id: c.id,
                        validation: c.validation ? c.validation : undefined,
                    };
                }),
            },
            {
                onSuccess: () => {
                    setSelectMode(false);
                    showNotification({ header: 'Certifications updated successfully' });
                    refetch();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    const changeSelection = useCallback(
        (id: number, selected: boolean, validation?: string) => {
            if (selected) {
                const newSelectedCertification = { id, validation };
                const newSelectedCertifications = selectedCertifications.filter(c => c.id !== id);

                setSelectedCertifications([...newSelectedCertifications, newSelectedCertification]);
            } else {
                setSelectedCertifications(selectedCertifications.filter(c => c.id !== id));
            }
        },
        [selectedCertifications],
    );

    useEffect(() => {
        if (certifications.length > 0) {
            setFilteredCertifications(
                certifications
                    .filter(
                        certification =>
                            certification.name.toLowerCase().includes(search.toLowerCase()) ||
                            (certification.issuer &&
                                certification.issuer.toLowerCase().includes(search.toLowerCase())),
                    )
                    .filter(c =>
                        showSelectedOnly
                            ? selectedCertifications.some(selectedC => c.id === selectedC.id)
                            : true,
                    ),
            );
        } else {
            setFilteredCertifications([]);
        }
    }, [certifications, search, showSelectedOnly, selectedCertifications]);
    const extUserSkillsId = user ? user.id + extSkillsIdAddition : user;
    const userId = isExternalRouter ? extUserSkillsId : user?.id;
    return (
        <form onSubmit={event => onSave(event)}>
            <FlexboxGrid justify="center">
                {isExternalRouter ? null : <FlexboxGrid.Item colspan={7}></FlexboxGrid.Item>}
                {isExternalRouter ? null : (
                    <FlexboxGrid.Item colspan={10}>
                        <div style={{ textAlign: 'center', marginBottom: 20 }}>
                            <Input
                                placeholder="Search certifications"
                                onChange={value => setSearch(value)}
                            />
                        </div>
                    </FlexboxGrid.Item>
                )}
                {isExternalRouter ? null : (
                    <FlexboxGrid.Item colspan={7}>
                        <Checkbox
                            checked={showSelectedOnly}
                            onChange={(value, checked) => setShowSelectedOnly(checked)}
                        >
                            Show only selected
                        </Checkbox>
                    </FlexboxGrid.Item>
                )}
                <>
                    {filteredCertifications.map(certification => (
                        <CertificationSelect
                            key={certification.id}
                            certification={certification}
                            changeSelection={changeSelection}
                            deleteCertification={deleteAndRefetch}
                            selectedCertifications={selectedCertifications}
                            userId={userId}
                        />
                    ))}
                    <AddCertification addCertification={addNewCert} />
                </>

                <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                        type="submit"
                        appearance="primary"
                        color="green"
                        style={{ width: 300 }}
                        loading={updateCertifications.isLoading}
                    >
                        Save
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </form>
    );
};
