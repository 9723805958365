import { zodResolver } from '@hookform/resolvers/zod';
import { queryClient } from 'App';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input, Row } from 'rsuite';

import { ExternalUserModel } from '../../generated-types/external_user';
import { useExternalUsers } from '../../hooks/useExternalUsers';
import { ExternalUserSchema, UpdateExternalUserType } from '../../schema/external_user';
import { DialogWrap } from '../misc/DialogWrap';
import { showCustomError, showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    externalUser?: ExternalUserModel;
    openDialog: boolean;
};

export function EditExternalUserDialog({ externalUser, openDialog }: Props) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { createExternalUser, updateExternalUser, deleteExternalUser } = useExternalUsers();
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<UpdateExternalUserType>({
        resolver: zodResolver(ExternalUserSchema),
    });

    const onSubmit: SubmitHandler<UpdateExternalUserType> = data => {
        const { id, ...rest } = data;

        if (externalUser?.id) {
            if (data.firstname === '' || data.lastname === '') {
                showCustomError({
                    header: 'Field can not be empty.',
                });
            } else {
                updateExternalUser.mutate(
                    { payload: rest, id: Number(id) },
                    {
                        onError: err => {
                            showErrorNotification(err);
                        },
                        onSuccess: () => {
                            showNotification({
                                header: 'External user edited!',
                            });
                            setOpen(false);
                            queryClient.invalidateQueries(['externalUsers']);
                        },
                    },
                );
            }
        } else {
            if (data.firstname === '' || data.lastname === '' || data.email === '') {
                showCustomError({
                    header: 'Field can not be empty.',
                });
            } else {
                createExternalUser.mutate(data, {
                    onError: err => {
                        showErrorNotification(err);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'External user added!',
                        });

                        setOpen(false);
                        queryClient.invalidateQueries(['externalUsers']);
                    },
                });
            }
        }
    };

    const handleTypeDelete = () => {
        if (externalUser?.id) {
            deleteExternalUser.mutate(externalUser?.id, {
                onError: error => {
                    showErrorNotification(error);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'External user succesfully deleted!',
                    });
                    setOpen(false);
                    queryClient.invalidateQueries(['externalUsers']);
                    navigate('/externalUsers');
                },
            });
        }
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={`${externalUser ? 'Edit' : 'Add'} User`}
                onSubmit={handleSubmit(onSubmit)}
                onDelete={handleTypeDelete}
            >
                {externalUser ? (
                    <input hidden {...register('id')} value={externalUser?.id} />
                ) : null}

                <input hidden {...register('updated_at')} value={Date()} />

                <Row>
                    <p>First name</p>
                    <Controller
                        name="firstname"
                        control={control}
                        defaultValue={externalUser?.firstname ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.firstname?.message}</p>
                </Row>
                <Row>
                    <p>Last name</p>
                    <Controller
                        name="lastname"
                        control={control}
                        defaultValue={externalUser?.lastname ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.lastname?.message}</p>
                </Row>
                {externalUser ? (
                    <input hidden {...register('email')} value={externalUser.email} />
                ) : (
                    <Row>
                        <p>Email</p>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <Input type="text" {...field} />}
                        />
                        <p className="error">{errors?.email?.message}</p>
                    </Row>
                )}
            </DialogWrap>
        </form>
    );
}
