import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { useMembers } from 'hooks/useMembers';
import { RefAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableProps, Tag, Tooltip, Whisper } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { CircleModel, CircleModelList } from '../../generated-types/circle';
import { MemberModel } from '../../generated-types/member';
import { useMemberships } from '../../hooks/useMemberships';
import { EurosCell, WeightCell } from '../Formats';
const { Column, HeaderCell, Cell } = Table;

type Props = {
    isLoading: boolean;
    data: CircleModelList;
    referenceMember?: MemberModel;
};

type TableData = {
    id: number;
    name: string;
    is_active: boolean;
    current_weight: number;
    current_membership_count: number;
    current_balance: number;
    __myRoles: string[];
    __memberSince: string;
    __revenueShare: number;
};

const NameCell = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => (
    <Cell {...props}>
        <span style={{ color: props.rowData.is_active ? '' : 'lightgrey' }}>
            {props.rowData.name}
        </span>
        {props.rowData.__requiresAttention && (
            <Whisper
                trigger="hover"
                placement="right"
                speaker={<Tooltip>Circle is missing role(s)</Tooltip>}
            >
                <RemindRoundIcon style={{ color: 'red', marginLeft: 5 }} />
            </Whisper>
        )}
    </Cell>
);

const MyRolesCell = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => (
    <Cell {...props}>
        {props.rowData.__myRoles.map((r: string) => {
            return <Tag key={r}>{r}</Tag>;
        })}
    </Cell>
);

export default function CircleTable(props: Props) {
    const [sortColumn, setSortColumn] = useState<keyof TableData>('name');
    const [sortType, setSortType] = useState<TableProps['sortType']>('desc');
    const { memberships } = useMemberships();
    const { members } = useMembers();
    const navigate = useNavigate();
    function setSort(sortColumn: keyof TableData) {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    }

    const getTableData = () => {
        const tableData: TableData[] = props.data.map((circle: CircleModel) => {
            const __requiresAttention =
                !members.find(member => member.id === circle.facilitator_id)?.is_active ||
                !members.find(member => member.id === circle.scribe_id)?.is_active ||
                !members.find(member => member.id === circle.treasurer_id)?.is_active;
            const currentMembership = props.referenceMember
                ? memberships.find(ms => {
                      return (
                          ms.circle_id === circle.id &&
                          props.referenceMember?.id === ms.member_id &&
                          !ms.end_date
                      );
                  })
                : null;

            const __myRoles: string[] = [];
            if (props.referenceMember?.id === circle.facilitator_id) __myRoles.push('Facilitator');
            if (props.referenceMember?.id === circle.scribe_id) __myRoles.push('Scribe');
            if (props.referenceMember?.id === circle.treasurer_id) __myRoles.push('Treasurer');

            return {
                id: circle.id,
                name: circle?.name || '',
                is_active: circle.is_active,
                current_weight: circle.current_weight,
                /* circles.current_membership_count does not filter out nonexistent members.
                 * Since it cannot be added to the query side easily, it is done here */
                current_membership_count: memberships.filter(
                    ms => ms.circle_id === circle.id && members.some(m => m.id === ms.member_id),
                ).length,
                current_balance: circle.current_balance,
                __requiresAttention,
                __myRoles,
                __memberSince: currentMembership?.start_date
                    ? currentMembership.start_date.substring(0, 10)
                    : '',
                __revenueShare: props.referenceMember
                    ? Math.round((circle.current_weight / props.referenceMember.weight_sum) * 100) /
                      100
                    : 0,
            };
        });

        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    return (
        <Table
            loading={props.isLoading}
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={setSort as (sortColumn: string) => void}
            data={getTableData()}
            onRowClick={row => {
                navigate('/circle/' + row.id);
            }}
        >
            <Column align="left" fixed flexGrow={1} minWidth={250} sortable>
                <HeaderCell>Circle</HeaderCell>
                <NameCell rowData={{}} dataKey="name" />
            </Column>
            {props.referenceMember ? (
                <Column align="right" sortable width={200}>
                    <HeaderCell>My Roles</HeaderCell>
                    <MyRolesCell rowData={{}} dataKey="__myRoles" />
                </Column>
            ) : (
                ''
            )}
            {props.referenceMember ? (
                <Column align="right" sortable width={130}>
                    <HeaderCell>Member Since</HeaderCell>
                    <Cell dataKey="__memberSince" />
                </Column>
            ) : (
                ''
            )}
            {props.referenceMember && (
                <Column align="right" sortable width={140}>
                    <HeaderCell>Revenue Share</HeaderCell>
                    <Cell dataKey="__revenueShare" />
                </Column>
            )}
            <Column align="right" sortable width={100}>
                <HeaderCell>Weight</HeaderCell>
                <WeightCell dataKey="current_weight" />
            </Column>
            <Column align="right" sortable width={100}>
                <HeaderCell>Members</HeaderCell>
                <Cell dataKey="current_membership_count" />
            </Column>
            <Column align="right" sortable width={100}>
                <HeaderCell>Balance</HeaderCell>
                <EurosCell dataKey="current_balance" />
            </Column>
        </Table>
    );
}
