import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsCertificationModelList,
    SkillsCertificationUpdatePayload,
    SkillsMemberCertificationModelList,
} from 'generated-types/skills/certification';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useMemberCertificationsHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id}/certifications/selected`
            : `${skillsDBPrefix}/members/${member_id}/certifications`;

        const { data, isLoading, isSuccess, isFetched, refetch } =
            useQuery<SkillsMemberCertificationModelList>(
                ['skills_certifications_member', member_id],
                () => lambdaApi.get(getUri).then(res => res.data),
                {
                    retry: false,
                    enabled: enabled && !!member_id,
                },
            );

        const updateUri = isExternal
            ? `${skillsDBPrefix}/ext/certifications/me`
            : `${skillsDBPrefix}/certifications/me`;

        const updateCertifications = useMutation<
            SkillsCertificationModelList,
            AxiosError,
            SkillsCertificationUpdatePayload
        >(payload => {
            return lambdaApi.put(updateUri, payload).then(res => res.data);
        });

        if (data) {
            data.sort((a, b) => a.name.localeCompare(b.name));
        }

        return {
            memberCertifications: data ?? [],
            updateCertifications,
            isLoading,
            isSuccess,
            isFetched,
            refetch,
        };
    };
};
