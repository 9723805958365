import jwtDecode from 'jwt-decode';

import config from '../config.json';
import { useLocalStorage } from '../hooks/misc/useLocalStorage';
import { extUserCognitoConfig } from './extUserCognitoConfig';
import { useState } from 'react';

export type CognitoSessionType = 'internalUsers' | 'externalUsers';

/** If found in query params, use external user authentication */
export const EXT_USER_INDICATOR = 'externalUser';

export const isExternalRouter = (() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (window.location.href.includes('cv.circles.fi')) {
        return true;
    } else if (searchParams.has(EXT_USER_INDICATOR)) {
        return true;
    }
})();

type CognitoConfig = {
    sessionType: CognitoSessionType;
    clientId: string;
    domain: string;
    storageKey: string;
    redirectUri: string;
    signInUri: string;
    signOutUri?: string;
};

const COGNITO_CLIENT_SECRET = false; // Not configured.

let cognitoConfig: CognitoConfig;

export const setCognitoSessionType = (sessionType: CognitoSessionType) => {
    const cognitoBaseConfig: Record<
        CognitoSessionType,
        { sessionType: CognitoSessionType; clientId: string; domain: string; storageKey: string }
    > = {
        internalUsers: {
            sessionType: 'internalUsers',
            clientId: config.MyCirclesUserPoolClientId,
            domain: config.InfraCirclesStaffUserPoolDomain,
            storageKey: 'CognitoSession',
        },
        externalUsers: {
            sessionType: 'externalUsers',
            clientId: extUserCognitoConfig.clientId,
            domain: extUserCognitoConfig.domain,
            storageKey: 'CognitoSessionExt',
        },
    };

    const baseConfig = cognitoBaseConfig[sessionType];

    let redirectUri = window.location.origin;

    if (sessionType === 'externalUsers' && window.location.href.includes('cv.circles.fi')) {
        redirectUri = window.location.origin;
    } else if (sessionType === 'externalUsers') {
        redirectUri += `?${EXT_USER_INDICATOR}=true`;
    }

    const redirectUriEncoded = encodeURIComponent(redirectUri);

    let params = `redirect_uri=${redirectUriEncoded}&response_type=CODE&client_id=${baseConfig.clientId}&scope=email+openid+profile`;

    if (sessionType === 'internalUsers') {
        params += `&identity_provider=Google`;
    }

    const signInUri = `https://${baseConfig.domain}/oauth2/authorize?${params}`;

    let signOutUri: string | undefined;

    if (sessionType === 'externalUsers') {
        signOutUri = `https://${baseConfig.domain}/logout?${params}&logout_uri=${redirectUriEncoded}`;
    }

    cognitoConfig = { ...baseConfig, signInUri, redirectUri, signOutUri };
};

type GrantType = 'authorization_code' | 'refresh_token';

type CognitoState = {
    accessToken: null | string;
    idToken: null | string;
    idTokenExpiresAt: number | null;
    idTokenPayload: null | string;
    refresh_token: null | string;
};

type TokenBody = {
    client_id: string;
    redirect_uri: string;
    client_secret?: string;
    code?: string;
    grant_type?: GrantType;
    refresh_token?: string;
};

const DEFAULT_STATE: CognitoState = {
    accessToken: null,
    idToken: null,
    idTokenExpiresAt: null,
    idTokenPayload: null,
    refresh_token: null,
};

let fetchTokensPromise: Promise<void>;
let state = { ...DEFAULT_STATE };

export const launchSigninFlow = () => {
    window.location.replace(cognitoConfig.signInUri);
};

export const resetCognitoSession = () => {
    state = { ...DEFAULT_STATE };
    localStorage.removeItem(cognitoConfig.storageKey);
};

export const signOutExtUser = () => {
    localStorage.removeItem(cognitoConfig.storageKey);

    window.location.replace(cognitoConfig.signOutUri as string);
};

export const getExtUserEmail = () => {
    const data = JSON.parse(localStorage.getItem(cognitoConfig.storageKey) as string);
    return data.idTokenPayload.email;
};

const readCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const code = searchParams.get('code');
    if (code) {
        resetCognitoSession();
        searchParams.delete('code');
        let newUri: string;
        // Our node types are badly outdated
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((searchParams as any).size) {
            newUri = `${window.location.origin}?${searchParams.toString()}`;
        } else {
            newUri = window.location.origin;
        }

        window.history.replaceState(null, document.title, newUri);
        console.log('Read and cleared the code from the url.');
    }
    const error_description = searchParams.get('error_description');
    if (error_description) {
        console.error(
            'Cognito error in the URL search params: ',
            error_description,
            searchParams.get('error'),
        );
    }

    return code;
};

const saveState = () => {
    localStorage.setItem(cognitoConfig.storageKey, JSON.stringify(state));
};

const fetchCredentials = async (code?: string) => {
    const { clientId, domain, redirectUri } = cognitoConfig;

    const body: TokenBody = {
        client_id: clientId,
        redirect_uri: redirectUri,
    };
    if (COGNITO_CLIENT_SECRET) {
        body.client_secret = COGNITO_CLIENT_SECRET;
    }
    if (code) {
        console.log('Fetching tokens with code..');
        body.code = code;
        body.grant_type = 'authorization_code';
    } else if (state.refresh_token) {
        console.log('Refreshing tokens..');
        body.grant_type = 'refresh_token';
        body.refresh_token = state.refresh_token;
    } else {
        throw new Error('COGNITO: Token requested without session.');
    }

    try {
        const resp = await (
            await fetch(`https://${domain}/oauth2/token`, {
                body: new URLSearchParams(body),
                method: 'POST',
            })
        ).json();
        state.accessToken = resp.access_token;
        state.idToken = resp.id_token;
        state.idTokenPayload = jwtDecode(state.idToken as string);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state.idTokenExpiresAt = (state.idTokenPayload as any).exp * 1000; // IdToken expiration time in ms.
        state.refresh_token = resp.refresh_token;

        console.log('Got new tokens.');

        saveState();
    } catch (e) {
        resetCognitoSession();
        console.error('Error fetching Cognito tokens.');
    }
};

export const getIdToken = async () => {
    await fetchTokensPromise;
    if ((state.idTokenExpiresAt as number) < new Date().getTime()) {
        fetchTokensPromise = fetchCredentials();
        await fetchTokensPromise;
    }
    if (!state.idToken) {
        throw new Error('No token even though we should have one.');
    }
    return state.idToken;
};

export const gotCognitoSession = () => {
    const code = readCodeFromUrl();

    if (code) {
        fetchTokensPromise = fetchCredentials(code);
    } else {
        const [cognitoSession] = useLocalStorage<CognitoState>(cognitoConfig.storageKey, {
            ...DEFAULT_STATE,
        });
        state = cognitoSession;
    }

    return !!code || !!state.idToken;
};
