import { useEffect } from 'react';

export function useOutsideClick(
    componentSelectors: string | string[],
    callback: () => void,
    condition?: boolean,
) {
    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            const target = event.target as Node;

            const array = Array.isArray(componentSelectors)
                ? componentSelectors
                : [componentSelectors];
            const components = array
                .map(selectors => document.querySelector(selectors))
                .filter(component => component) as Element[];

            const isOutsideClick = components.every(component => !component.contains(target));

            if (condition !== false && isOutsideClick) {
                callback();
            }
        }
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [componentSelectors, callback, condition]);
}
