import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsMemberSkillModelList,
    SkillsMemberSkillUpdatePayloadList,
} from 'generated-types/skills/skill';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useMemberSkillsHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id}/skills`
            : `${skillsDBPrefix}/members/${member_id}/skills`;

        const { data, isLoading, refetch } = useQuery<SkillsMemberSkillModelList>(
            ['skills_member_skills', member_id],
            () => lambdaApi.get(getUri, {}).then(res => res.data),
            {
                retry: false,
                enabled: enabled && !!member_id,
            },
        );

        const updateUri = isExternal
            ? `${skillsDBPrefix}/ext/skills/me`
            : `${skillsDBPrefix}/skills/me`;

        const updateMySkills = useMutation<
            SkillsMemberSkillModelList,
            AxiosError,
            SkillsMemberSkillUpdatePayloadList
        >(payload => {
            return lambdaApi.put(updateUri, payload).then(res => res.data);
        });

        let result;

        if (data) {
            result = data.sort((a, b) => a.name.localeCompare(b.name));
        }

        return {
            isLoading,
            memberSkills: result ?? [],
            updateMySkills,
            refetch,
        };
    };
};
