import { css } from '@emotion/css';
import LegacyPlusIcon from '@rsuite/icons/legacy/Plus';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { SubmitHandler } from 'react-hook-form';
import { Button, ButtonProps, ButtonToolbar, Modal } from 'rsuite';

type ButtonStyle = {
    appearance: ButtonProps['appearance'];
    color: ButtonProps['color'];
    size: ButtonProps['size'];
    showPlusIcon: boolean;
};

type Props = {
    children: (JSX.Element | null)[];
    title: string;
    shortTitle?: string;
    onSubmit: SubmitHandler<any>;
    onDelete?: () => void;
    open: boolean;
    setOpen: (arg: boolean) => void;
    buttonOpenStyle?: ButtonStyle;
};

const ModalCSS = css`
    p.error {
        color: red;
    }
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0em 0.5em 0em 0.5em;
`;

export function DialogWrap({
    children,
    title,
    onSubmit,
    onDelete,
    shortTitle,
    open,
    setOpen,
    buttonOpenStyle: bOpen,
}: Props) {
    return (
        <>
            <ButtonToolbar>
                <Button
                    appearance={bOpen?.appearance ?? 'primary'}
                    color={bOpen?.color ?? 'blue'}
                    size={bOpen?.size ?? 'md'}
                    onClick={() => setOpen(true)}
                >
                    {bOpen?.showPlusIcon ? <LegacyPlusIcon /> : null} {shortTitle ?? title}
                </Button>
            </ButtonToolbar>

            <Modal backdrop={'static'} open={open} onClose={() => setOpen(false)}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={ModalCSS}>{children}</Modal.Body>
                <Modal.Footer style={{ paddingTop: '1em' }}>
                    <Button onClick={onSubmit} appearance="primary" color="green">
                        {title}
                    </Button>
                    <Button onClick={() => setOpen(false)} appearance="subtle">
                        Cancel
                    </Button>
                    {typeof onDelete === 'function' && (
                        <ConfirmationDialog
                            handleOK={onDelete}
                            header="Delete"
                            message="Are you sure you want to delete this?"
                        >
                            <Button appearance="primary" color="red" style={{ marginLeft: '1em' }}>
                                Delete
                            </Button>
                        </ConfirmationDialog>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
