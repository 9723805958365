import { AnonymousSkillsScreen } from 'components/screens/anonymous-cv/Anonymous-cv';
import { CSSProperties, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { Container, Content, Panel } from 'rsuite';

const OPEN_WHEN_WIDER_THAN = 800;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300 * 1000,
        },
    },
});

export const AnonymousApp = () => {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const expanded = viewPortWidth >= OPEN_WHEN_WIDER_THAN;

    const updateWindowDimensions = () => {
        setViewPortWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Container style={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <Content>
                    <Panel>
                        <Routes>
                            <Route path="/" element={<AnonymousSkillsScreen />} />
                            <Route
                                path="/resume/preview"
                                element={<AnonymousSkillsScreen previewMode={true} />}
                            />
                            <Route
                                path="/resume/:userId/preview"
                                element={<AnonymousSkillsScreen previewMode={true} />}
                            />
                            <Route path="/resume/:userId" element={<AnonymousSkillsScreen />} />
                        </Routes>
                    </Panel>
                </Content>
            </Container>
        </QueryClientProvider>
    );
};
