import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import { SkillsCompanyModelList } from 'generated-types/skills/company';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export type UseCompaniesType = {
    label: string;
    value: number;
    group: string;
    created: boolean;
};

export const useCompaniesHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id}/companies`
            : `${skillsDBPrefix}/companies`;

        const { data, isLoading, refetch } = useQuery<SkillsCompanyModelList>(
            ['skills_companies'],
            () => lambdaApi.get(getUri).then(res => res.data),
            {
                retry: false,
                enabled,
            },
        );

        let result: UseCompaniesType[] = [];

        if (data) {
            result = data
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(company => ({
                    label: company.name,
                    value: company.id,
                    group: company.circles_customer_id ? 'Circles Customers' : 'External Companies',
                    created: false,
                }));
        }

        const syncCompanies = useMutation<SkillsCompanyModelList, AxiosError, unknown>(() => {
            return lambdaApi.post(`${skillsDBPrefix}/companies/sync`).then(res => res.data);
        });

        return {
            isLoading,
            companies: result,
            syncCompanies,
            refetch,
        };
    };
};
