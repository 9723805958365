import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import { ProfileUpdatePayload, SkillsMemberModel } from 'generated-types/skills/member';
import { SkillsExternalMemberModel } from 'generated-types/skills/external_member';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useSkillsMemberHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id ?? 'me'}`
            : `${skillsDBPrefix}/members/${member_id ?? 'me'}`;

        const { data, isLoading, isSuccess, isFetched, refetch } = useQuery<SkillsMemberModel>(
            ['skills_member', member_id],
            () => lambdaApi.get(getUri).then(res => res.data),
            {
                retry: false,
                enabled,
            },
        );

        const updateUri = isExternal
            ? `${skillsDBPrefix}/members/ext/me`
            : `${skillsDBPrefix}/members/me`;

        const updateSkillsMember = useMutation<SkillsMemberModel, AxiosError, ProfileUpdatePayload>(
            payload => {
                return lambdaApi.put(updateUri, payload).then(res => res.data);
            },
        );

        const syncSkillsMembers = useMutation<SkillsMemberModel, AxiosError, unknown>(() => {
            return lambdaApi.post(`${skillsDBPrefix}/members/sync`).then(res => res.data);
        });

        const syncExternalSkillsMembers = useMutation<
            SkillsExternalMemberModel,
            AxiosError,
            unknown
        >(() => {
            return lambdaApi.post(`${skillsDBPrefix}/members/ext/sync`).then(res => res.data);
        });

        return {
            skillsMember: data,
            isLoading,
            isSuccess,
            isFetched,
            updateSkillsMember,
            syncSkillsMembers,
            syncExternalSkillsMembers,
            refetch,
        };
    };
};

export function useExternalPicture({
    enabled,
    member_id,
}: {
    enabled: boolean;
    member_id?: number;
}) {
    const { data, isLoading, isSuccess, isFetched, refetch } = useQuery<string>(
        ['skills_member_picture', member_id],
        () => lambdaApi.get(`${skillsDBPrefix}/members/${member_id}/picture`).then(res => res.data),
        {
            retry: false,
            enabled,
        },
    );
    return {
        skillsMemberPicture: data,
        isLoading,
        isSuccess,
        isFetched,
        refetch,
    };
}

export function useExtMemberPicture({
    enabled,
    member_id,
}: {
    enabled: boolean;
    member_id?: number;
}) {
    const { data, isLoading, isSuccess, isFetched, refetch } = useQuery<string>(
        ['ext_skills_member', member_id],
        () => lambdaApi.get(`${skillsDBPrefix}/members/ext/picture`).then(res => res.data),
        {
            retry: false,
            enabled,
        },
    );

    const addExtSkillsMemberPicture = useMutation<FormData, AxiosError, FormData>(payload => {
        return lambdaApi
            .post(`${skillsDBPrefix}/members/ext/picture`, payload)
            .then(res => res.data);
    });

    const deleteExtSkillsMemberPicture = useMutation<'', AxiosError, number>(member_id => {
        return lambdaApi
            .delete(`${skillsDBPrefix}/members/ext/picture/${member_id}`)
            .then(res => res.data);
    });

    return {
        skillsMemberPicture: data,
        isLoading,
        isSuccess,
        isFetched,
        addExtSkillsMemberPicture,
        deleteExtSkillsMemberPicture,
        refetch,
    };
}
