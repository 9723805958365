import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsExperienceModel,
    SkillsExperienceModelList,
    SkillsExperienceRequestPayload,
} from 'generated-types/skills/experience';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useExperiencesHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id}/experiences`
            : `${skillsDBPrefix}/members/${member_id}/experiences`;

        const { data, isLoading, refetch } = useQuery<SkillsExperienceModelList>(
            ['skills_experiences', member_id],
            () => lambdaApi.get(getUri).then(res => res.data),
            { retry: false, enabled: enabled && !!member_id },
        );

        if (data) {
            data.sort((a, b) => {
                return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
            });
        }

        const postUri = isExternal
            ? `${skillsDBPrefix}/ext/experiences`
            : `${skillsDBPrefix}/experiences`;

        const addExperience = useMutation<
            SkillsExperienceModelList,
            AxiosError,
            SkillsExperienceRequestPayload
        >(payload => {
            return lambdaApi.post(postUri, payload).then(res => res.data);
        });

        const updateExperience = useMutation<
            SkillsExperienceModel,
            AxiosError,
            { id: number; payload: SkillsExperienceRequestPayload }
        >(params => {
            const { id, payload } = params;
            const updateUri = isExternal
                ? `${skillsDBPrefix}/ext/experiences/${id}`
                : `${skillsDBPrefix}/experiences/${id}`;

            return lambdaApi.put(updateUri, payload).then(res => res.data);
        });

        const deleteExperience = useMutation<'', AxiosError, number>(id => {
            const deleteUri = isExternal
                ? `${skillsDBPrefix}/ext/experiences/${id}`
                : `${skillsDBPrefix}/experiences/${id}`;

            return lambdaApi.delete(deleteUri).then(res => res.data);
        });

        return {
            isLoading,
            experiences: data ?? [],
            addExperience,
            updateExperience,
            deleteExperience,
            refetch,
        };
    };
};
