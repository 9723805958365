import { queryClient } from 'App';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, Row } from 'rsuite';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogWrap } from '../misc/DialogWrap';
import { showCustomError, showErrorNotification, showNotification } from '../ShowNotification';
import { useMembers } from 'hooks/useMembers';
import { ExternalUserModel } from 'generated-types/external_user';
import { CreateMember, CreateMemberType } from 'schema/member';
import { useExternalUsers } from 'hooks/useExternalUsers';
import { useNavigate } from 'react-router-dom';

type Props = {
    externalUser?: ExternalUserModel;
    openDialog: boolean;
};

export function MoveExternalUserDialog({ externalUser, openDialog }: Props) {
    const [open, setOpen] = useState(false);
    const { moveMember } = useMembers();
    const { deleteExternalUser } = useExternalUsers();
    const navigate = useNavigate();
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<CreateMemberType>({
        resolver: zodResolver(CreateMember),
    });

    const onSubmit: SubmitHandler<CreateMemberType> = data => {
        if (data.email === '' || data.google_id === '') {
            showCustomError({
                header: 'Field can not be empty.',
            });
        } else if (externalUser) {
            moveMember.mutate(
                { payload: data, id: externalUser.id },
                {
                    onError: error => {
                        showErrorNotification(error);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'User succesfully moved!',
                        });
                        deleteExternalUser.mutate(externalUser.id);
                        setOpen(false);
                        queryClient.invalidateQueries(['skills_member']);
                        queryClient.invalidateQueries(['externalUsers']);
                        navigate('/externalUsers');
                    },
                },
            );
        } else {
            showCustomError({
                header: 'You have not chosen a profile to move!',
            });
        }
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={
                    open
                        ? `Move ${externalUser?.lastname} ${externalUser?.firstname} to internal members `
                        : `Move user`
                }
                onSubmit={handleSubmit(onSubmit)}
            >
                <input hidden {...register('firstname')} value={externalUser?.firstname} />
                <input hidden {...register('lastname')} value={externalUser?.lastname} />
                <Row>
                    <p>Circles email</p>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.email?.message}</p>
                </Row>
                <Row>
                    <p>Google id</p>
                    <Controller
                        name="google_id"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.google_id?.message}</p>
                </Row>
                <Row>
                    <p>Employment start</p>
                    <Controller
                        name="employment_start"
                        control={control}
                        defaultValue={undefined}
                        render={({ field }) => (
                            <DatePicker oneTap style={{ width: 140 }} {...field} />
                        )}
                    />
                    <p className="error">{errors?.employment_start?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
