import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MYSQL_DATE_STRING } from '../config';
import { MemberModel } from '../generated-types/member';
import { CreateMemberType, MemberTypeList, UpdateMemberType } from '../schema/member';
import { useProfile } from './useProfile';

export function useMembers(showInactive = false, sortByName = false, disabled = false) {
    const { isAdmin } = useProfile();

    const { data, isLoading, refetch } = useQuery<MemberModel[]>(
        ['members', showInactive],
        () => {
            const url = isAdmin ? '/members' : '/me/members';
            return lambdaApi.get(url, {}).then(res => res.data);
        },
        {
            enabled: !disabled,
        },
    );

    const updateMember = useMutation<MemberTypeList, AxiosError, { payload: UpdateMemberType }>(
        props => {
            const { payload } = props;
            const { id, employment_start, ...rest } = payload;

            return lambdaApi
                .put(`/members/${id}`, {
                    employment_start: format(employment_start, MYSQL_DATE_STRING),
                    ...rest,
                })
                .then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const moveMember = useMutation<
        MemberModel,
        AxiosError,
        { payload: CreateMemberType; id: number }
    >(
        props => {
            const { payload, id } = props;
            const { employment_start, is_active, is_admin, ...rest } = payload;

            return lambdaApi
                .post(`/members/ext_to_internal/${id}`, {
                    employment_start: format(employment_start, MYSQL_DATE_STRING),
                    is_active: true,
                    is_admin: false,

                    ...rest,
                })
                .then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    let filteredData: MemberModel[] = [];

    if (data) {
        filteredData = data;
        if (!showInactive) {
            filteredData = filteredData.filter(member => member.is_active);
        }
        if (sortByName) {
            filteredData.sort((a, b) => a.lastname.localeCompare(b.lastname));
        }
    }

    const roles: { role: MemberModel['role']; name: string }[] = [
        {
            role: 'staff',
            name: 'Staff',
        },
        {
            role: 'consultant',
            name: 'Consultant',
        },
    ];

    return {
        members: filteredData,
        roles,
        isLoading,
        updateMember,
        moveMember,
    };
}
