import { signOutExtUser } from 'auth/cognito';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { Button, DropdownProps, FlexboxGrid, Panel, Toggle } from 'rsuite';

export const AnonymousHeaderPanel = () => {
    const [theme, setTheme] = useLocalStorage('theme', 'default');

    return (
        <Panel>
            <FlexboxGrid justify="space-between" style={{ alignItems: 'center' }}>
                <FlexboxGrid.Item>
                    <h2>My Resume</h2>
                </FlexboxGrid.Item>
                <div>
                    <Toggle
                        style={{ marginLeft: 20, marginRight: 20 }}
                        checkedChildren="Dark"
                        unCheckedChildren="Default"
                        checked={theme === 'dark'}
                        onChange={() => {
                            setTheme(theme == 'dark' ? 'default' : 'dark');
                            window.location.reload();
                        }}
                    />
                    <Button
                        onClick={signOutExtUser}
                        style={{ marginLeft: 5, background: 'dodgerblue' }}
                    >
                        Log me out
                    </Button>
                </div>
            </FlexboxGrid>
        </Panel>
    );
};
