import { SkillsMemberCertificationModel } from 'generated-types/skills/certification';
import { Link } from 'react-router-dom';
import { Input, Panel } from 'rsuite';

import CertificateBlank from '../../../assets/icons/certificate_blank.png';

const CertificationBagde = ({ badge_image_url }: { badge_image_url?: string }) =>
    badge_image_url ? (
        <img height={150} src={badge_image_url} alt="certification badge" />
    ) : (
        <img height={150} src={CertificateBlank} alt="certificate" />
    );

const CertificationValidation = ({ validation }: { validation?: string }) => {
    const validationIsUrl = validation && validation?.startsWith('http');
    return (
        <div
            style={{
                width: '100%',
                height: 30,
                marginTop: 20,
                fontFamily: 'Monospace',
            }}
        >
            {validationIsUrl ? (
                <a href={validation} target="blank">
                    Validate
                </a>
            ) : (
                validation && <Input size="xs" readOnly value={validation} />
            )}
        </div>
    );
};

export const Certification = ({
    certification,
}: {
    certification: SkillsMemberCertificationModel;
}) => (
    <Panel
        shaded
        key={certification.id}
        style={{
            width: 300,
            height: 320,
            margin: 10,
            position: 'relative',
        }}
        header={
            <div
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textAlign: 'center',
                }}
            >
                {certification.issuer && <small>{certification.issuer}</small>}
                <h6>{certification.name}</h6>
            </div>
        }
    >
        <div
            style={{
                textAlign: 'center',
                position: 'absolute',
                bottom: 10,
                right: 10,
                left: 10,
            }}
        >
            <Link
                to={`/resume/search?certifications=${certification.id}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <CertificationBagde badge_image_url={certification?.badge_image_url} />
            </Link>
            <CertificationValidation validation={certification?.validation} />
        </div>
    </Panel>
);
