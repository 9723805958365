import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const StyledArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 1em;
    gap: 2em;
`;

export const StyledHomeScreen = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2em;
`;

export const CalendarArea = styled.div<{ disabled: boolean }>`
    width: 25em;
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    .rs-calendar-table-cell-content {
        position: relative;
    }
`;

export const YellowOverlay = css`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: hsla(70, 60%, 80%, 20%);
    border: 1px solid hsla(70, 60%, 60%, 40%);
`;

export const CircleContainerStyle = css`
    width: 65px;
    display: inline-block;
    margin: 5px 10px 5px 10px;
    vertical-align: top;
    font-size: 13px;
    text-align: center;
`;

export const CircleStyle = css`
    width: 50px;
    margin: auto;
`;

export const formStyles = css`
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 0em 0.5em 0em 0em;
`;

export const ErrorText = styled.h6`
    color: red;
`;

export const SuccessText = styled.h6`
    color: green;
`;

export const ErrorParagraph = styled.p`
    color: red;
`;

export const ProjectQuickSelectBox = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

export const ProjectSelectionBox = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    border: 1px solid grey;
    box-shadow: 10px 10px 5px silver;
    margin: 1em 0em 0em 0em;
    padding: 1em;
`;

export const paidLeaveColor = '#01afd9';
export const unpaidLeaveColor = '#d9165c';
export const sickLeaveColor = '#9a9a9a';
export const customerProjectColor = '#4caf50';
export const internalProjectColor = '#ffb300';

export const BadgeStyle = {
    customer: { background: customerProjectColor, margin: '1px' },
    internal: { background: internalProjectColor, margin: '1px' },
    paid: { background: paidLeaveColor, margin: '1px' },
    unpaid: { background: unpaidLeaveColor, margin: '1px' },
    sick: { background: sickLeaveColor, margin: '1px' },
};
