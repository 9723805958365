import { zodResolver } from '@hookform/resolvers/zod';
import { queryClient } from 'App';
import { useInternalProjectTypes } from 'hooks/useInternalProjectTypes';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input, Row } from 'rsuite';

import { InternalProjectTypeModel } from '../../generated-types/internal_project_type';
import { useProfile } from '../../hooks/useProfile';
import { InternalProjectRecordType, InternalProjectType } from '../../schema/internal_project_type';
import { DialogWrap } from '../misc/DialogWrap';
import { showCustomError, showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    internalProjectType?: InternalProjectTypeModel;
};

export function EditInternalProjectTypeDialog({ internalProjectType }: Props) {
    const { user } = useProfile();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { updateInternalProjectType, createInternalProjectType, deleteInternalProjectType } =
        useInternalProjectTypes();
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<InternalProjectType>({
        resolver: zodResolver(InternalProjectRecordType),
    });

    const onSubmit: SubmitHandler<InternalProjectType> = data => {
        const { id, ...rest } = data;

        if (data.name === '') {
            showCustomError({
                header: 'Internal Project Type must have a name.',
            });
        } else if (id) {
            updateInternalProjectType.mutate(
                { payload: rest, id: Number(id) },
                {
                    onError: err => {
                        showErrorNotification(err);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Internal Project Type edited!',
                        });
                        setOpen(false);
                        queryClient.invalidateQueries(['internal_project_types']);
                    },
                },
            );
        } else {
            createInternalProjectType.mutate(rest, {
                onError: err => {
                    showErrorNotification(err);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Internal Project type created!',
                    });

                    setOpen(false);
                    queryClient.invalidateQueries(['internal_project_types']);
                },
            });
        }
    };

    const handleTypeDelete = () => {
        if (internalProjectType?.id) {
            deleteInternalProjectType.mutate(internalProjectType?.id, {
                onError: error => {
                    showErrorNotification(error);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Internal project type deleted!',
                    });
                    setOpen(false);
                    queryClient.invalidateQueries(['internal_project_types']);
                    navigate('/internalprojecttypes');
                },
            });
        }
    };

    if (!user) {
        return null;
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={`${internalProjectType ? 'Edit' : 'Create'} Internal Project Type`}
                onSubmit={handleSubmit(onSubmit)}
                onDelete={handleTypeDelete}
            >
                {internalProjectType ? (
                    <input hidden {...register('id')} value={internalProjectType?.id} />
                ) : null}

                <Row>
                    <p>Name</p>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={internalProjectType?.name ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.name?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={internalProjectType?.description ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
