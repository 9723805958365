import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { useEducationsHook } from 'hooks/skills/useEducations';
import { useExperiencesHook } from 'hooks/skills/useExperiences';
import { useMemberCertificationsHook } from 'hooks/skills/useMemberCertifications';
import { useMemberSkillsHook } from 'hooks/skills/useMemberSkills';
import { useSkillsMemberHook } from 'hooks/skills/useSkillsMember';
import { useStatusHook } from 'hooks/skills/useStatus';
import { useMyExternalProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { QueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'rsuite';

import { CVEditor } from '../skills/CVEditor';
import { AnonymousHeaderPanel } from './Anonymous-HeaderPanel';
import { isExternalRouter } from 'auth/cognito';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300 * 1000,
        },
    },
});

export function AnonymousSkillsScreen({ previewMode = false }: { previewMode?: boolean }) {
    const navigate = useNavigate();
    const { user } = useMyExternalProfile();
    const [selectedMemberId, setSelectedMemberId] = useState<number | undefined>();
    const [selectedSkills, setSelectedSkills] = useState<number[]>([]);
    const [selectedCertifications, setSelectedCertifications] = useState<number[]>([]);
    const member_id =
        selectedMemberId && selectedMemberId !== user?.id ? selectedMemberId : user?.id;
    const useStatus = useStatusHook(isExternalRouter);
    const { isAvailable, isFetched: statusIsFetched } = useStatus();
    const useSkillsMembers = useSkillsMemberHook(isExternalRouter);
    const {
        skillsMember,
        isLoading: skillsMemberIsLoading,
        isFetched: skillsMemberIsFetched,
    } = useSkillsMembers({
        enabled: isAvailable,
        member_id,
    });
    const useMemberSkills = useMemberSkillsHook(isExternalRouter);
    const { memberSkills, isLoading: skillsLoading } = useMemberSkills({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useExperiences = useExperiencesHook(isExternalRouter);
    const { experiences, isLoading: experiencesLoading } = useExperiences({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useEducations = useEducationsHook(isExternalRouter);
    const { educations, isLoading: educationsLoading } = useEducations({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useMemberCertifications = useMemberCertificationsHook(isExternalRouter);
    const { memberCertifications, isLoading: certificationsLoading } = useMemberCertifications({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });

    const [circlesExperiences, otherExperiences] = experiences.reduce(
        (acc, experience) => {
            if (experience.company?.circles_customer_id || experience.is_internal_project) {
                acc[0].push(experience);
            } else {
                acc[1].push(experience);
            }
            return acc;
        },
        [[], []] as [SkillsExperienceModelList, SkillsExperienceModelList],
    );
    const skillsExist = selectedSkills.length > 0;
    const certificationsExist = selectedCertifications.length > 0;

    useEffect(() => {
        if (user?.id && !isNaN(Number(user?.id))) {
            setSelectedMemberId(Number(user?.id));
        } else {
            setSelectedMemberId(undefined);
        }
    }, [user?.id]);

    useEffect(() => {
        let navigatePath = '/';

        if (skillsExist || certificationsExist) {
            navigatePath += 'search?';

            if (skillsExist) {
                const skills = encodeURIComponent(selectedSkills.join(','));
                navigatePath += `skills=${skills}`;
            }
            if (certificationsExist) {
                if (skillsExist) {
                    navigatePath += '&';
                }
                const certifications = encodeURIComponent(selectedCertifications.join(','));
                navigatePath += `certifications=${certifications}`;
            }
        } else if (!user?.id && !previewMode) {
            navigate(navigatePath);
        }
    }, [selectedSkills, selectedCertifications]);

    const editAllowed = user?.id === selectedMemberId || !selectedMemberId;
    return (
        <>
            {skillsMemberIsLoading || !skillsMember ? (
                <Loader center content={'Resume is loading...'} />
            ) : (
                <>
                    <AnonymousHeaderPanel />
                    <CVEditor
                        editAllowed={editAllowed}
                        memberSkills={memberSkills}
                        skillsMember={skillsMember}
                        circlesExperiences={circlesExperiences}
                        otherExperiences={otherExperiences}
                        educations={educations}
                        experiencesLoading={experiencesLoading}
                        educationsLoading={educationsLoading}
                        skillsLoading={skillsLoading}
                        certificationsLoading={certificationsLoading}
                        memberCertifications={memberCertifications}
                    />
                </>
            )}
        </>
    );
}
