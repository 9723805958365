import UnvisibleIcon from '@rsuite/icons/Unvisible';
import VisibleIcon from '@rsuite/icons/Visible';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import {
    SkillsMemberSkillModel,
    SkillsMemberSkillModelList,
    SkillsMemberSkillUpdatePayloadList,
} from 'generated-types/skills/skill';
import { useMemberSkillsHook } from 'hooks/skills/useMemberSkills';
import { useProfile, useMyExternalProfile } from 'hooks/useProfile';
import { useState } from 'react';
import { Button, ButtonGroup, FlexboxGrid, IconButton, Panel, Slider } from 'rsuite';
import { isExternalRouter } from 'auth/cognito';

const SkillEdit = ({
    skill,
    levels,
    setLevels,
    changeVisibility,
}: {
    skill: SkillsMemberSkillModel;
    levels: SkillsMemberSkillModelList;
    setLevels: React.Dispatch<React.SetStateAction<SkillsMemberSkillModelList>>;
    changeVisibility: (index: number, visible: boolean) => void;
}) => {
    const skillIndex = levels.findIndex(l => l.id === skill.id);
    const noSkillLevel = !skill.level || skill.level === 0;
    const skillHidden = skill.show_on_cv === false || noSkillLevel;

    const renderSkillMark = (mark: number) => {
        const SkillLevelDictionary: { [key: number]: string } = {
            0: 'None',
            20: 'Intermediate',
            50: 'Advanced',
            90: 'Expert',
        };
        return (
            <span style={{ color: mark === 0 ? 'lightgray' : 'gray' }}>
                {SkillLevelDictionary[mark]}
            </span>
        );
    };

    const changeSkillLevel = (value: number) => {
        const index = levels.findIndex(l => l.id === skill.id);
        if (index !== -1) {
            const newLevels = levels;
            if (value === 0) {
                newLevels[index].level = undefined;
            } else {
                newLevels[index].level = value;
            }
            setLevels([...newLevels]);
        }
    };

    return (
        <FlexboxGrid.Item key={skillIndex} colspan={12} style={{ marginBottom: 20 }}>
            <FlexboxGrid>
                <FlexboxGrid.Item style={{ paddingTop: 3 }}>
                    {skillHidden ? (
                        <IconButton
                            size="sm"
                            appearance="primary"
                            color="red"
                            disabled={noSkillLevel}
                            icon={<UnvisibleIcon />}
                            onClick={() => changeVisibility(skillIndex, true)}
                            alt="Show on CV"
                        />
                    ) : (
                        <IconButton
                            size="sm"
                            appearance="subtle"
                            color="green"
                            disabled={noSkillLevel}
                            icon={<VisibleIcon />}
                            onClick={() => changeVisibility(skillIndex, false)}
                            alt="Hide on CV"
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <h4 style={{ paddingLeft: 5 }}>{skill.name}</h4>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <p style={{ height: 15, textAlign: 'center' }}>
                {skill.level ? `${skill.level}%` : null}
            </p>
            <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 12 }}>
                <Slider
                    style={{ marginLeft: 20 }}
                    defaultValue={skill.level ?? 0}
                    min={0}
                    step={10}
                    max={100}
                    graduated
                    progress
                    tooltip={false}
                    renderMark={mark => renderSkillMark(mark)}
                    onChange={value => changeSkillLevel(value)}
                />
            </div>
        </FlexboxGrid.Item>
    );
};

export const SkillsEdit = ({
    skills,
    setEditMode,
}: {
    skills: SkillsMemberSkillModelList;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const suggestedTopSkillValue = 12;
    const [levels, setLevels] = useState<SkillsMemberSkillModelList>(
        skills.map(skill => ({
            id: skill.id,
            name: skill.name,
            level: skill.level,
            show_on_cv: skill.show_on_cv,
        })),
    );
    const hookToUseForProfile = isExternalRouter ? useMyExternalProfile : useProfile;
    const { user } = hookToUseForProfile();
    const useMemberSkills = useMemberSkillsHook(isExternalRouter);
    const { updateMySkills, refetch } = useMemberSkills({
        enabled: false,
        member_id: user?.id,
    });

    const onSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const payload: SkillsMemberSkillUpdatePayloadList = levels.map(skill => ({
            id: skill.id,
            level: skill.level,
            show_on_cv: skill.show_on_cv,
        }));
        updateMySkills.mutate(payload, {
            onSuccess: () => {
                setEditMode(false);
                showNotification({ header: 'Skills updated successfully' });
                refetch();
            },
            onError: error => {
                showErrorNotification(error);
            },
        });
    };

    const changeVisibility = (index: number, visible: boolean) => {
        if (index >= 0) {
            const newLevels = levels;
            newLevels[index].show_on_cv = visible;
            setLevels([...newLevels]);
        }
    };

    const selectTopSkills = () => {
        const topSkills: SkillsMemberSkillModelList = Object.create(levels);
        topSkills
            .filter(skill => skill.level)
            .sort((a, b) => {
                if (!a.level) return 1;
                if (!b.level) return -1;
                if (a.level > b.level) return -1;
                return 1;
            })
            .forEach((skill, index) => {
                const skillIndex = levels.findIndex(l => l.id === skill.id);
                if (index < suggestedTopSkillValue) {
                    changeVisibility(skillIndex, true);
                } else {
                    changeVisibility(skillIndex, false);
                }
            });
    };

    const selectAllSkills = (select: boolean) => {
        levels.forEach((skill, index) => {
            if (skill?.level && skill.level > 0) {
                changeVisibility(index, select);
            }
        });
    };
    return (
        <form onSubmit={event => onSave(event)}>
            <FlexboxGrid justify="space-between">
                {levels.map(skill => (
                    <SkillEdit
                        key={skill.id}
                        skill={skill}
                        levels={levels}
                        setLevels={setLevels}
                        changeVisibility={changeVisibility}
                    />
                ))}
                <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center' }}>
                    <Panel
                        bordered
                        style={{
                            marginBottom: 20,
                            textAlign: 'center',
                            width: 400,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <ButtonGroup>
                            <Button appearance="link" onClick={() => selectAllSkills(true)}>
                                Display all
                            </Button>
                            <Button appearance="link" onClick={() => selectTopSkills()}>
                                Display top ({suggestedTopSkillValue})
                            </Button>
                            <Button appearance="link" onClick={() => selectAllSkills(false)}>
                                Hide all
                            </Button>
                        </ButtonGroup>
                    </Panel>
                    <Button
                        type="submit"
                        appearance="primary"
                        color="green"
                        style={{ width: 300 }}
                        loading={updateMySkills.isLoading}
                    >
                        Save
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </form>
    );
};
