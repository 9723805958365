import { SkillsMemberModel } from 'generated-types/skills/member';
import { useMemberCertificationsHook } from 'hooks/skills/useMemberCertifications';
import { useMemberSkillsHook } from 'hooks/skills/useMemberSkills';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FlexboxGrid, Panel, Tag, TagGroup } from 'rsuite';
import { isExtUserId } from '../Profile';
import { isExternalRouter } from 'auth/cognito';

export const MemberCard = ({
    member,
    selectedSkills,
    selectedCertifications,
}: {
    member: SkillsMemberModel;
    selectedSkills: number[];
    selectedCertifications: number[];
}) => {
    const useMemberSkills = useMemberSkillsHook(isExternalRouter);
    const { memberSkills } = useMemberSkills({ member_id: member.member_id, enabled: true });
    const useMemberCertifications = useMemberCertificationsHook(isExternalRouter);
    const { memberCertifications } = useMemberCertifications({
        member_id: member.member_id,
        enabled: true,
    });
    const sortedSkills = memberSkills.sort((a, b) => {
        if (!a.level) return 1;
        if (!b.level) return -1;
        return b.level - a.level;
    });

    const borderColor = isExtUserId(member.member_id) ? 'royalblue' : '';

    return (
        <Link
            to={`/resume/${member.member_id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <Panel bordered style={{ marginBottom: 20, borderColor }}>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item colspan={2} style={{ textAlign: 'center' }}>
                        <img
                            src={member.image_url}
                            style={{ maxWidth: 120, width: '100%', borderRadius: '50%' }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={22} style={{ paddingLeft: 20 }}>
                        <h2>
                            {member.firstname} {member.lastname}
                        </h2>
                        <h4>{member.headline}</h4>
                        <p
                            style={{
                                paddingTop: 10,
                                textAlign: 'justify',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {member.about}
                        </p>
                        <TagGroup style={{ marginTop: 10 }}>
                            {sortedSkills.map(skill => (
                                <Tag
                                    size="lg"
                                    color={selectedSkills.includes(skill.id) ? 'green' : undefined}
                                    key={skill.id}
                                >
                                    {skill.name} {skill?.level && `(${skill.level}%)`}
                                </Tag>
                            ))}
                            {memberCertifications.map(certification => (
                                <Tag
                                    size="lg"
                                    color={
                                        selectedCertifications.includes(certification.id)
                                            ? 'green'
                                            : undefined
                                    }
                                    key={certification.id}
                                >
                                    {certification.name}
                                </Tag>
                            ))}
                        </TagGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </Link>
    );
};
