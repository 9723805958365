import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    NewCertificationModel,
    SkillsCertificationModel,
    SkillsCertificationModelList,
} from 'generated-types/skills/certification';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useCertificationsHook = (isExternal?: boolean) => {
    return ({ enabled, member_id }: { enabled: boolean; member_id?: number }) => {
        const getUri = isExternal
            ? `${skillsDBPrefix}/members/ext/${member_id}/certifications`
            : `${skillsDBPrefix}/certifications`;

        const { data, isLoading, refetch } = useQuery<SkillsCertificationModelList>(
            ['skills_certifications'],
            () => lambdaApi.get(getUri).then(res => res.data),
            {
                retry: false,
                enabled,
            },
        );

        const postUri = isExternal
            ? `${skillsDBPrefix}/ext/certifications`
            : `${skillsDBPrefix}/certifications`;

        const addCertification = useMutation<
            SkillsCertificationModel,
            AxiosError,
            NewCertificationModel
        >(
            ['skills_certifications'],
            payload => lambdaApi.post(postUri, payload).then(res => res.data),
            {
                retry: false,
            },
        );

        const deleteCertification = useMutation<null, AxiosError, { id: number }>(
            ['skills_certifications'],
            payload => {
                const deleteUri = isExternal
                    ? `${skillsDBPrefix}/ext/certifications/${payload.id}`
                    : `${skillsDBPrefix}/certifications/${payload.id}`;

                return lambdaApi.delete(deleteUri).then(res => res.data);
            },
            { retry: false },
        );

        if (data) {
            data.sort((a, b) => a.name.localeCompare(b.name));
        }

        return {
            isLoading,
            addCertification,
            deleteCertification,
            certifications: data ?? [],
            refetch,
        };
    };
};
