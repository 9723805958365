import * as z from 'zod';

import { mysqlDate } from '.';

export const UpdateExternalUser = z.object({
    id: z.union([z.number(), z.string()]).optional(),
    email: z.string().email(),
    firstname: z.string().min(2),
    lastname: z.string().min(2),
    updated_at: z.union([z.date(), z.string()]).optional(),
});

export const ExternalUserSchema = z.object({
    id: z.union([z.number(), z.string()]).optional(),
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    updated_at: z.union([mysqlDate, z.string()]).optional(),
});

export const ExternalUserList = z.array(ExternalUserSchema);

export type ExternalUserType = z.infer<typeof ExternalUserSchema>;
export type ExternalUserTypeList = z.infer<typeof ExternalUserList>;
export type UpdateExternalUserType = z.infer<typeof UpdateExternalUser>;
