import { Image, Page, Text, View } from '@react-pdf/renderer';
import logo from 'assets/circles_logo_cv.png';
import { SkillsMemberModel } from 'generated-types/skills/member';

import { pageSize, SideGradientPanel, styles } from './styles';

export const IntroductionPage = ({
    member,
    externalPicture,
}: {
    member: SkillsMemberModel;
    externalPicture?: string;
}) => {
    let profilePictureUri: string;
    if (externalPicture) {
        profilePictureUri = externalPicture;
    } else if (member.image_url) {
        profilePictureUri = member.image_url.replace('=s96-c', '=s500-c');
    } else {
        profilePictureUri = ' ';
    }
    return (
        <Page size={pageSize} style={styles.introPage} wrap={false}>
            <View style={styles.logoContainer}>
                <Image style={styles.logo} src={logo} />
            </View>
            <SideGradientPanel />
            <View style={styles.profile}>
                <View style={styles.pictureContainer}>
                    <Image style={styles.userPicture} src={profilePictureUri} />
                </View>
                <View style={styles.informationContainer}>
                    <Text style={styles.name} hyphenationCallback={word => [word]}>
                        {member.firstname} {member.lastname}
                    </Text>
                    <Text style={styles.headline} hyphenationCallback={word => [word]}>
                        {member?.headline}
                    </Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.about} hyphenationCallback={word => [word]}>
                            {member?.about}
                        </Text>
                    </View>
                </View>
            </View>
        </Page>
    );
};
