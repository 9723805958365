import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MYSQL_DATE_STRING } from '../config';
import {
    ExternalUserList,
    ExternalUserModel,
    ExternalUserRequestPayload,
} from '../generated-types/external_user';
import { ExternalUserTypeList, UpdateExternalUserType } from '../schema/external_user';

type Props = {
    sortByName: boolean;
};

export function useExternalUsers(props?: Props) {
    const { data, isLoading, isSuccess } = useQuery<ExternalUserList>(['externalUsers'], () =>
        lambdaApi.get('/externalUsers', {}).then(res => res.data),
    );

    const createExternalUser = useMutation<
        ExternalUserModel,
        AxiosError,
        ExternalUserRequestPayload
    >(payload => {
        return lambdaApi.post('/externalUsers', payload).then(res => res.data);
    });

    const updateExternalUser = useMutation<
        ExternalUserTypeList,
        AxiosError,
        { id: number; payload: UpdateExternalUserType }
    >(props => {
        const { payload, id } = props;
        const { updated_at, ...rest } = payload;
        return lambdaApi
            .put(`/externalUsers/${id}`, {
                ...rest,
            })
            .then(res => res.data);
    });

    const deleteExternalUser = useMutation<ExternalUserModel, AxiosError, number>(id => {
        return lambdaApi.delete(`/externalUsers/${id}`).then(res => res.data);
    });

    let filterData: ExternalUserList = [];
    if (data) {
        filterData = data;
        if (props?.sortByName) {
            filterData.sort((a, b) => {
                return a.lastname < b.lastname ? -1 : 1;
            });
        }
    }
    return {
        updateExternalUser: updateExternalUser,
        deleteExternalUser: deleteExternalUser,
        createExternalUser: createExternalUser,
        externalUsers: filterData,
        isLoading,
        isSuccess,
    };
}
