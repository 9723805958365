import lambdaApi from 'api/LambdaApi';
import { useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export const useStatusHook = (isExternal?: boolean) => {
    return () => {
        const getUri = isExternal ? `${skillsDBPrefix}/ext/status` : `${skillsDBPrefix}/status`;

        const { data, isLoading, isFetched } = useQuery(
            ['skills_db_status'],
            () => lambdaApi.get(getUri, {}).then(res => res.data),
            {
                retry: false,
            },
        );

        const isAvailable = data?.status === 'ok';

        return {
            isAvailable,
            isLoading,
            isFetched,
        };
    };
};
