import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { useOutsideClick } from 'hooks/misc/useOutsideClick';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Dropdown, Grid, Panel, Row } from 'rsuite';

import { TimeTrackModel } from '../../../generated-types/timetrack';
import { useTimeTracks } from '../../../hooks/useTimeTracks';
import { getThisMonthRange } from '../../../utils/dateUtils';
import { filterOnlyOneDayTimetracks } from '../../../utils/timetrack';
import { LogWorkForm } from './log-work-form';
import { LoggedHours } from './logged-hours-view';
import { LoggedHoursCalendar } from './LoggedHoursCalendar';
import { LogLeaveFormEdit } from './LogLeaveFormEdit';
import { LogLeaveFormNew } from './LogLeaveFormNew';
import { StyledArea, StyledHomeScreen } from './styles';
import { getInternalProjectSuggestion } from './suggestions';

type TTType = 'Absence' | 'Work';
export type LogHoursApplyParams = 'date' | 'timetrack_id' | 'new_record_type';

export const scrollToElement = (element: 'header' | '#edit') => {
    document.querySelector(element)?.scrollIntoView({ behavior: 'smooth' });
};

export function LogHoursScreen() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [hourLogType, setHourLogType] = useState<TTType>('Work');
    const [timetrackIdForSelection, setTimetrackIdForSelection] = useState<number | undefined>();
    const [currentlyEditedTimetrack, setCurrentlyEditedTimetrack] = useState<
        TimeTrackModel | undefined
    >();
    const { user } = useProfile();
    const { timetracks, ttQuery, setTtQuery } = useTimeTracks({});
    const onlyCurrentDayTimeTracks = filterOnlyOneDayTimetracks(selectedDate, timetracks);

    const dayHourTotal = onlyCurrentDayTimeTracks.reduce((a, b) => {
        return a + b.hours;
    }, 0);
    const existingDayLocation = onlyCurrentDayTimeTracks?.[0]?.location;

    const internalProjectIdSuggestion = getInternalProjectSuggestion(timetracks);

    const applyParams = (param: LogHoursApplyParams, value: string) => {
        if (value.length > 0) {
            searchParams.set(param, value);
            setSearchParams(searchParams);
        } else {
            searchParams.delete(param);
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        const init_date = searchParams.get('date') || undefined;
        const parsed_date = init_date && parseISO(init_date);
        const date = isValid(parsed_date) && parsed_date !== '' ? parsed_date : undefined;
        if (date) setSelectedDate(date);
        const monthRange = date ? getThisMonthRange(date) : getThisMonthRange(new Date());

        const timetrack_id = searchParams.get('timetrack_id') || undefined;

        if (timetrack_id && !isNaN(Number(timetrack_id))) {
            setTimetrackIdForSelection(Number(timetrack_id));
        } else {
            setTimetrackIdForSelection(undefined);
        }

        const new_record_type = searchParams.get('new_record_type') || undefined;
        if (
            new_record_type &&
            !timetrack_id &&
            (new_record_type === 'Absence' || new_record_type === 'Work')
        )
            setHourLogType(new_record_type);

        setTtQuery({
            start_date: monthRange[0],
            end_date: monthRange[1],
            count: 500,
            members: user ? [user.id] : undefined,
        });
    }, [searchParams, user]);

    useEffect(() => {
        const timetrack = timetracks.find(tt => tt.id === Number(timetrackIdForSelection));
        if (timetrack) {
            setSelectedDate(parseISO(timetrack.date));
            setCurrentlyEditedTimetrack(timetrack);
            timetrack.leave_type ? setHourLogType('Absence') : setHourLogType('Work');
            scrollToElement('#edit');
        } else {
            setCurrentlyEditedTimetrack(undefined);
        }
    }, [timetrackIdForSelection, timetracks]);

    const handleClickOutside = () => {
        setHourLogType('Work');
        applyParams('timetrack_id', '');
        scrollToElement('header');
    };

    useOutsideClick(
        ['.rs-grid-container-fluid', '.rs-modal-wrapper'],
        handleClickOutside,
        Boolean(currentlyEditedTimetrack),
    );

    return (
        <StyledHomeScreen>
            <div>
                <StyledArea>
                    <Panel bordered shaded>
                        <LoggedHoursCalendar
                            selectedTimetrack={currentlyEditedTimetrack}
                            timetracks={timetracks}
                            selectedDate={selectedDate}
                            applyParams={applyParams}
                        />
                    </Panel>

                    <LoggedHours
                        timetracks={onlyCurrentDayTimeTracks}
                        selectedDate={selectedDate}
                        disabled={Boolean(currentlyEditedTimetrack)}
                        applyParams={applyParams}
                    />
                </StyledArea>
            </div>

            <Panel bordered shaded style={{ width: '600px' }}>
                <Grid fluid>
                    <Row>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                <h4 style={{ display: 'inline', fontWeight: '500' }} id="edit">
                                    {currentlyEditedTimetrack ? 'Edit' : 'New'}
                                </h4>
                                <span style={{ marginLeft: '0.5em' }}>
                                    <Dropdown
                                        activeKey={hourLogType}
                                        size="md"
                                        disabled={Boolean(currentlyEditedTimetrack)}
                                        appearance="ghost"
                                        color="cyan"
                                        title={`${hourLogType} timetrack`}
                                    >
                                        <Dropdown.Item
                                            onSelect={() => applyParams('new_record_type', 'Work')}
                                        >
                                            Work
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onSelect={() =>
                                                applyParams('new_record_type', 'Absence')
                                            }
                                        >
                                            Absence
                                        </Dropdown.Item>
                                    </Dropdown>
                                </span>
                            </span>
                            {currentlyEditedTimetrack ? (
                                <Button
                                    onClick={() => {
                                        setHourLogType('Work');
                                        applyParams('timetrack_id', '');
                                        scrollToElement('header');
                                    }}
                                    color="orange"
                                    appearance="ghost"
                                >
                                    Cancel
                                </Button>
                            ) : null}
                        </div>
                    </Row>

                    {hourLogType === 'Work' && (
                        <LogWorkForm
                            existingDayLocation={existingDayLocation}
                            internalProjectIdSuggestion={internalProjectIdSuggestion}
                            dayHourTotal={dayHourTotal}
                            key={currentlyEditedTimetrack?.id}
                            selectedTimetrack={currentlyEditedTimetrack}
                            ttQuery={ttQuery}
                            selectedDate={selectedDate}
                            applyParams={applyParams}
                        />
                    )}

                    {hourLogType === 'Absence' && currentlyEditedTimetrack && (
                        <LogLeaveFormEdit
                            key={currentlyEditedTimetrack?.id}
                            selectedTimetrack={currentlyEditedTimetrack}
                            ttQuery={ttQuery}
                            selectedDate={selectedDate}
                            applyParams={applyParams}
                        />
                    )}

                    {hourLogType === 'Absence' && !currentlyEditedTimetrack && (
                        <LogLeaveFormNew
                            key={selectedDate.toISOString()}
                            ttQuery={ttQuery}
                            selectedDate={selectedDate}
                        />
                    )}
                </Grid>
            </Panel>
        </StyledHomeScreen>
    );
}
