import ExternalUsersTable from 'components/tables/ExternalUsersTable';
import { useExternalUsers } from 'hooks/useExternalUsers';
import { Col, FlexboxGrid, Grid, Loader, Panel } from 'rsuite';

import { EditExternalUserDialog } from '../dialogs/EditExternalUserDialog';
import { useProfile } from '../../hooks/useProfile';
import { useState } from 'react';

const ExternalUsersScreen = () => {
    const { isAdmin } = useProfile();
    const { externalUsers } = useExternalUsers();

    const [openDialog, setOpenDialog] = useState(false);

    return ExternalUsersTable ? (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>External Users</h1>
                            </Panel>
                            <Panel>
                                {isAdmin ? (
                                    <EditExternalUserDialog openDialog={openDialog} />
                                ) : null}
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel bordered bodyFill header={<h3>Approved external users</h3>}>
                        {<ExternalUsersTable data={externalUsers} />}
                    </Panel>
                </Col>
            </Grid>
        </div>
    ) : (
        <Loader />
    );
};

export default ExternalUsersScreen;
