import { zodResolver } from '@hookform/resolvers/zod';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { FieldInputLength } from 'components/FieldInputLength';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isFuture from 'date-fns/isFuture';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { SkillsCompanyRequestPayload } from 'generated-types/skills/company';
import { SkillsExperienceModel } from 'generated-types/skills/experience';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useCompaniesHook, UseCompaniesType } from 'hooks/skills/useCompanies';
import { useExperiencesHook } from 'hooks/skills/useExperiences';
import { useMemberSkillsHook } from 'hooks/skills/useMemberSkills';
import { useSkillsHook, UseSkillsType } from 'hooks/skills/useSkills';
import { useProfile, useMyExternalProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Col,
    DatePicker,
    FlexboxGrid,
    Input,
    InputPicker,
    Panel,
    Row,
    TagPicker,
} from 'rsuite';
import {
    EditExperienceForm,
    EditExperienceFormType,
    experienceDescriptionMaxLength,
    titleMaxLength,
} from 'schema/skills/experience';
import { isExternalRouter } from 'auth/cognito';

import { ErrorParagraph } from '../log-hours/styles';

export const ExperienceEditForm = ({
    experience,
    setEditMode,
}: {
    experience: SkillsExperienceModel;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [startDate, setStartDate] = useState<Date | undefined>(new Date(experience.start_date));
    const [endDate, setEndDate] = useState<Date | undefined>(
        experience.end_date ? new Date(experience.end_date) : undefined,
    );
    const [isInternalProject, setIsInternalProject] = useState<boolean>(
        experience.is_internal_project,
    );
    const [selectedCompany, setSelectedCompany] = useState<UseCompaniesType | undefined>(
        experience.company && {
            label: experience.company.name,
            value: experience.company.id,
            group: experience.company.circles_customer_id
                ? 'Circles Customers'
                : 'External Companies',
            created: false,
        },
    );
    const [newCompanies, setNewCompanies] = useState<UseCompaniesType[]>([]);
    const [newSkills, setNewSkills] = useState<UseSkillsType>([]);
    const hookToUseForUser = isExternalRouter ? useMyExternalProfile : useProfile;
    const { user } = hookToUseForUser();
    const useCompanies = useCompaniesHook(isExternalRouter);
    const { companies, refetch: refetchCompanies } = useCompanies({
        enabled: true,
        member_id: user?.id,
    });
    const useSkills = useSkillsHook(isExternalRouter);
    const { skills, refetch: refetchSkills } = useSkills({ enabled: true });
    const useExperiences = useExperiencesHook(isExternalRouter);
    const {
        updateExperience,
        deleteExperience,
        refetch: refetchExperiences,
    } = useExperiences({ enabled: false, member_id: user?.id });
    const useMemberSkills = useMemberSkillsHook(isExternalRouter);
    const { refetch: refetchMemberSkills } = useMemberSkills({
        enabled: false,
        member_id: user?.id,
    });
    const {
        formState: { errors },
        watch,
        control,
        handleSubmit,
        register,
        resetField,
    } = useForm<EditExperienceFormType>({
        resolver: zodResolver(EditExperienceForm),
    });
    const watchFields = watch();

    const onSave: SubmitHandler<EditExperienceFormType> = data => {
        const { id, title, description, start_date, selected_skills } = data;
        const experienceId = Number(id);
        const company: SkillsCompanyRequestPayload | undefined =
            selectedCompany && !isInternalProject
                ? {
                      name: selectedCompany.label,
                      existing_company_id: selectedCompany.created
                          ? undefined
                          : selectedCompany.value,
                  }
                : undefined;

        updateExperience.mutate(
            {
                id: experienceId,
                payload: {
                    title,
                    description,
                    start_date: format(startDate ? startDate : start_date, 'yyyy-MM-dd'),
                    end_date: endDate && format(endDate, 'yyyy-MM-dd'),
                    skills: selected_skills
                        ? selected_skills.map(
                              s =>
                                  skills.find(s2 => s2.value == s)?.label || // endpoint expects labels
                                  s, // If the skill is new, value contains the name already
                          )
                        : undefined,
                    company,
                    is_internal_project: isInternalProject,
                },
            },
            {
                onSuccess: () => {
                    setEditMode(false);
                    showNotification({ header: 'Experience updated sucessfully.' });
                    refetchExperiences();
                    refetchMemberSkills();
                    refetchCompanies();
                    refetchSkills();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };
    const onClose = () => {
        setEditMode(false);
        resetField('title');
        resetField('description');
        resetField('start_date');
        resetField('end_date');
        resetField('selected_skills');
    };
    const handleDelete = (id: number) => {
        deleteExperience.mutate(id, {
            onSuccess: () => {
                showNotification({ header: 'Experience deleted sucessfully.' });
                refetchMemberSkills();
                refetchExperiences();
            },
            onError: error => {
                showErrorNotification(error);
            },
        });
    };

    const handleRangeSelect = (dateType: 'start' | 'end', date: Date) => {
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        if (dateType === 'start') {
            date.setDate(1);
            setStartDate(date);
        } else {
            date.setDate(lastDayOfMonth(date).getDate());
            setEndDate(date);
        }
    };

    useEffect(() => {
        if (isInternalProject) {
            setSelectedCompany(undefined);
        }
    }, [isInternalProject]);

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <input hidden {...register('id')} defaultValue={experience.id} />
            <Panel
                bordered
                shaded
                key={experience.id}
                style={{
                    marginBottom: 20,
                    background: 'white',
                    color: theme === 'dark' ? '#0f131a' : 'inherit',
                }}
            >
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Row style={{ margin: 0 }}>
                            <p>
                                Title<span style={{ color: 'red' }}>*</span>
                            </p>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={experience.title}
                                render={({ field }) => (
                                    <>
                                        <Input size="lg" {...field} />
                                        <FieldInputLength
                                            currentLength={watchFields.title?.length}
                                            maxLength={titleMaxLength}
                                        />
                                    </>
                                )}
                            />
                            <ErrorParagraph>{errors.title?.message}</ErrorParagraph>
                        </Row>
                        <Row style={{ paddingBottom: 10 }}>
                            <Col>
                                <p>Company</p>
                                <InputPicker
                                    creatable
                                    value={selectedCompany ? selectedCompany.value : null}
                                    onSelect={(value, item) => {
                                        setSelectedCompany({
                                            label: String(item.label),
                                            value: Number(value),
                                            group: item.group,
                                            created: item.created,
                                        });
                                    }}
                                    onClean={() => setSelectedCompany(undefined)}
                                    onCreate={(value, item) => {
                                        const newId =
                                            Math.max(
                                                ...companies
                                                    .concat(newCompanies)
                                                    .map(company => company.value),
                                            ) + 1;
                                        const newCompany = {
                                            label: String(item.label),
                                            value: newId,
                                            group: item.group,
                                            created: true,
                                        };
                                        setNewCompanies([...newCompanies, newCompany]);
                                        setSelectedCompany(newCompany);
                                    }}
                                    placeholder="Select or create new"
                                    groupBy="group"
                                    data={companies.concat(newCompanies)}
                                    disabled={isInternalProject}
                                />
                            </Col>
                            <Col style={{ paddingTop: 20 }}>
                                <Checkbox
                                    checked={isInternalProject}
                                    onChange={() => setIsInternalProject(!isInternalProject)}
                                >
                                    Circles Internal Project
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ paddingBottom: 10 }}>
                            <Col>
                                Start Date<span style={{ color: 'red' }}>*</span>{' '}
                                <Controller
                                    name="start_date"
                                    control={control}
                                    defaultValue={new Date(experience.start_date)}
                                    render={({ field }) => (
                                        <DatePicker
                                            oneTap
                                            disabledDate={date => {
                                                if (date && endDate) {
                                                    return isAfter(date, endDate);
                                                } else if (date) {
                                                    return isFuture(date);
                                                }
                                                return false;
                                            }}
                                            format="MMM yyyy"
                                            ranges={[]}
                                            limitEndYear={50}
                                            onSelect={date => handleRangeSelect('start', date)}
                                            onClean={() => setStartDate(undefined)}
                                            onEmptied={() => setStartDate(undefined)}
                                            {...field}
                                        />
                                    )}
                                />
                                <ErrorParagraph>{errors.start_date?.message}</ErrorParagraph>
                            </Col>
                            <Col>
                                End Date{' '}
                                <Controller
                                    name="end_date"
                                    control={control}
                                    defaultValue={
                                        experience.end_date ? new Date(experience.end_date) : null
                                    }
                                    render={({ field }) => (
                                        <DatePicker
                                            oneTap
                                            disabledDate={date => {
                                                if (date && startDate) {
                                                    return isBefore(date, startDate);
                                                }
                                                return false;
                                            }}
                                            ranges={[]}
                                            format="MMM yyyy"
                                            limitEndYear={50}
                                            onSelect={date => handleRangeSelect('end', date)}
                                            onClean={() => setEndDate(undefined)}
                                            onEmptied={() => setEndDate(undefined)}
                                            {...field}
                                        />
                                    )}
                                />
                                <ErrorParagraph>{errors.end_date?.message}</ErrorParagraph>
                            </Col>
                        </Row>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                appearance="primary"
                                color="green"
                                type="submit"
                                loading={updateExperience.isLoading}
                            >
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Close</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={24}>
                        <p>
                            Description<span style={{ color: 'red' }}>*</span>
                        </p>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={experience.description}
                            render={({ field }) => (
                                <>
                                    <Input size="lg" as="textarea" rows={6} {...field} />
                                    <FieldInputLength
                                        currentLength={watchFields.description?.length}
                                        maxLength={experienceDescriptionMaxLength}
                                    />
                                </>
                            )}
                        />
                        <ErrorParagraph>{errors.description?.message}</ErrorParagraph>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid
                    justify="space-between"
                    align="bottom"
                    style={{ paddingTop: 20, alignContent: 'center' }}
                >
                    <FlexboxGrid.Item>
                        <p>Skills</p>
                        <Controller
                            name="selected_skills"
                            control={control}
                            defaultValue={experience.skills?.map(skill => `skill-${skill.id}`)}
                            render={({ field }) => (
                                <TagPicker
                                    creatable
                                    onCreate={(value, item) => {
                                        setNewSkills([
                                            ...newSkills,
                                            {
                                                label: String(item.label),
                                                value: String(item.value),
                                            },
                                        ]);
                                    }}
                                    placeholder="Select or create new"
                                    data={skills.concat(newSkills)}
                                    style={{ width: 500 }}
                                    {...field}
                                />
                            )}
                        />
                        <ErrorParagraph>{errors.selected_skills?.message}</ErrorParagraph>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <ConfirmationDialog
                            handleOK={() => handleDelete(experience.id)}
                            header={'Delete Work Experience'}
                            message={'Are you sure you want to delete this work experience?'}
                        >
                            <Button
                                appearance="primary"
                                color="red"
                                loading={deleteExperience.isLoading}
                            >
                                Delete
                            </Button>
                        </ConfirmationDialog>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </form>
    );
};
