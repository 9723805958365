import ReloadIcon from '@rsuite/icons/Reload';
import { isExternalRouter } from 'auth/cognito';
import { SearchBar } from 'components/screens/skills/search/SearchBar';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { useCompaniesHook } from 'hooks/skills/useCompanies';
import { useSkillsMemberHook } from 'hooks/skills/useSkillsMember';
import { useProfile } from 'hooks/useProfile';
import { Button, ButtonGroup, FlexboxGrid, IconButton, Panel } from 'rsuite';

export const ToolBar = ({
    setSelectedMemberId,
    selectedMemberId,
    selectedMinLevel,
    setSelectedMinLevel,
    selectedSkills,
    setSelectedSkills,
    previewMode,
    skillsEnabled,
    selectedCertifications,
    setSelectedCertifications,
}: {
    setSelectedMemberId: React.Dispatch<React.SetStateAction<number | undefined>>;
    selectedMinLevel: number;
    setSelectedMinLevel: React.Dispatch<React.SetStateAction<number>>;
    selectedMemberId: number | undefined;
    selectedSkills: number[];
    setSelectedSkills: React.Dispatch<React.SetStateAction<number[]>>;
    previewMode: boolean;
    skillsEnabled: boolean;
    selectedCertifications: number[];
    setSelectedCertifications: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
    const { user, isAdmin } = useProfile();
    const useCompanies = useCompaniesHook(isExternalRouter);
    const { syncCompanies, refetch: refetchCompanies } = useCompanies({ enabled: false });
    const useSkillsMembers = useSkillsMemberHook(isExternalRouter);
    const {
        syncSkillsMembers,
        syncExternalSkillsMembers,
        refetch: refetchSkillsMembers,
    } = useSkillsMembers({
        enabled: false,
        member_id: user?.id,
    });

    const onSyncCompanies = () => {
        syncCompanies.mutate(
            {},
            {
                onSuccess: () => {
                    showNotification({ header: 'Companies are synced' });
                    refetchCompanies();
                },
                onError: error => showErrorNotification(error),
            },
        );
    };

    const onSyncMembers = () => {
        syncSkillsMembers.mutate(
            {},
            {
                onSuccess: () => {
                    showNotification({ header: 'Members are synced' });
                    refetchSkillsMembers();
                },
                onError: error => showErrorNotification(error),
            },
        );
    };

    const onSyncExternalMembers = () => {
        syncExternalSkillsMembers.mutate(
            {},
            {
                onSuccess: () => {
                    showNotification({ header: 'Members are synced' });
                    refetchSkillsMembers();
                },
                onError: error => showErrorNotification(error),
            },
        );
    };
    return (
        <Panel bordered style={{ marginBottom: 20 }}>
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <SearchBar
                        selectedSkills={selectedSkills}
                        setSelectedSkills={setSelectedSkills}
                        selectedMinLevel={selectedMinLevel}
                        setSelectedMinLevel={setSelectedMinLevel}
                        selectedMemberId={selectedMemberId}
                        setSelectedMemberId={setSelectedMemberId}
                        previewMode={previewMode}
                        skillsEnabled={skillsEnabled}
                        user={user}
                        selectedCertifications={selectedCertifications}
                        setSelectedCertifications={setSelectedCertifications}
                    />
                </FlexboxGrid.Item>
                {isAdmin && (
                    <FlexboxGrid.Item style={{ marginTop: 10 }}>
                        <ButtonGroup>
                            <IconButton icon={<ReloadIcon />} onClick={onSyncMembers}>
                                Sync members
                            </IconButton>
                            <IconButton
                                icon={<ReloadIcon />}
                                style={{ marginLeft: 5, marginRight: 5 }}
                                onClick={onSyncExternalMembers}
                            >
                                Sync external members
                            </IconButton>
                            <Button onClick={onSyncCompanies}>Sync companies</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
        </Panel>
    );
};
