import { AxiosError } from 'axios';
import { MutateOptions } from 'react-query';
import { Notification, toaster } from 'rsuite';
import { MessageType } from 'rsuite/esm/Notification/Notification';

export type Toast = {
    body?: string | undefined;
    closable?: boolean | undefined;
    duration?: number;
    header: string;
    type?: MessageType;
};

export function showErrorNotification(err: AxiosError) {
    const error = err.response?.data;
    // @ts-ignore
    const code = error?.Code ?? 'Unknown';
    // @ts-ignore
    const message = error?.Message ?? 'Unknown message';

    showNotification({
        type: 'error',
        header: code,
        body: message,
    });
}

export function showNotification({
    header,
    body = '',
    type = 'success',
    duration = 2000,
    closable = false,
}: Toast) {
    const message = (
        <Notification type={type} header={header} closable={closable} duration={duration}>
            {body}
        </Notification>
    );
    toaster.push(message, { placement: 'bottomEnd' });
}

export function showCustomError({
    header,
    body = '',
    type = 'warning',
    duration = 2000,
    closable = false,
}: Toast) {
    const message = (
        <Notification type={type} header={header} closable={closable} duration={duration}>
            {body}
        </Notification>
    );
    toaster.push(message, { placement: 'bottomEnd' });
}

export function mutationNotification(
    successMessage: string,
): MutateOptions<unknown, AxiosError, unknown> {
    return {
        onError: err => showErrorNotification(err),
        onSuccess: () => showNotification({ header: successMessage }),
    };
}
