import { PDFViewer } from '@react-pdf/renderer';
import { SkillsMemberCertificationModelList } from 'generated-types/skills/certification';
import { SkillsEducationModelList } from 'generated-types/skills/education';
import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { SkillsMemberModel } from 'generated-types/skills/member';
import { SkillsSkillModelList } from 'generated-types/skills/skill';
import { Panel } from 'rsuite';

import { PdfCV } from './pdf-cv';

export const PdfViewer = ({
    circlesExperiences,
    otherExperiences,
    skillsMember,
    certifications,
    memberSkills,
    educations,
    externalPicture,
}: {
    circlesExperiences: SkillsExperienceModelList;
    otherExperiences: SkillsExperienceModelList;
    skillsMember: SkillsMemberModel;
    certifications: SkillsMemberCertificationModelList;
    memberSkills: SkillsSkillModelList;
    educations: SkillsEducationModelList;
    externalPicture?: string;
}) => (
    <>
        <Panel>
            <PDFViewer width="100%" height="1000px">
                <PdfCV
                    circlesExperiences={circlesExperiences}
                    otherExperiences={otherExperiences}
                    member={skillsMember}
                    skills={memberSkills}
                    certifications={certifications}
                    educations={educations}
                    externalPicture={externalPicture}
                />
            </PDFViewer>
        </Panel>
    </>
);
