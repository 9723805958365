import { zodResolver } from '@hookform/resolvers/zod';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { SkillsEducationModel } from 'generated-types/skills/education';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useEducationsHook } from 'hooks/skills/useEducations';
import { useProfile, useMyExternalProfile } from 'hooks/useProfile';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, ButtonGroup, Col, DatePicker, FlexboxGrid, Input, Panel, Row } from 'rsuite';
import { EditEducationForm, EditEducationFormType } from 'schema/skills/education';
import { isExternalRouter } from 'auth/cognito';

import { ErrorParagraph } from '../log-hours/styles';

export const EducationEditForm = ({
    education,
    setEditMode,
}: {
    education: SkillsEducationModel;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [endDate, setEndDate] = useState<Date | undefined>(
        education.end_date ? new Date(education.end_date) : undefined,
    );
    const hookToUseForProfile = isExternalRouter ? useMyExternalProfile : useProfile;
    const { user } = hookToUseForProfile();

    const useEducations = useEducationsHook(isExternalRouter);

    const {
        updateEducation,
        deleteEducation,
        refetch: refetchEducations,
    } = useEducations({ enabled: false, member_id: user?.id });
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        resetField,
    } = useForm<EditEducationFormType>({
        resolver: zodResolver(EditEducationForm),
    });

    const onSave: SubmitHandler<EditEducationFormType> = data => {
        const { degree, description } = data;
        updateEducation.mutate(
            {
                id: education.id,
                payload: {
                    degree,
                    description,
                    end_date: endDate && format(endDate, 'yyyy-MM-dd'),
                },
            },
            {
                onSuccess: () => {
                    showNotification({ header: 'Education updated successfully' });
                    setEditMode(false);
                    refetchEducations();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    const onClose = () => {
        setEditMode(false);
        resetField('degree', { defaultValue: education.degree });
        resetField('description', { defaultValue: education.description });
    };

    const handleDelete = (id: number) => {
        deleteEducation.mutate(id, {
            onSuccess: () => {
                showNotification({ header: 'Education deleted successfully' });
                refetchEducations();
            },
            onError: error => {
                showErrorNotification(error);
            },
        });
    };

    const handleRangeSelect = (date: Date) => {
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        date.setDate(lastDayOfMonth(date).getDate());
        setEndDate(date);
    };
    return (
        <form onSubmit={handleSubmit(onSave)}>
            <input hidden {...register('id')} defaultValue={education.id} />
            <Panel
                bordered
                shaded
                key={education.id}
                style={{
                    marginBottom: 20,
                    background: 'white',
                    color: theme === 'dark' ? '#0f131a' : 'inherit',
                }}
            >
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={8}>
                        <Row style={{ paddingBottom: 10 }}>
                            <p>
                                Degree<span style={{ color: 'red' }}>*</span>
                            </p>
                            <Controller
                                name="degree"
                                control={control}
                                defaultValue={education.degree}
                                render={({ field }) => <Input size="lg" {...field} />}
                            />
                            <ErrorParagraph>{errors.degree?.message}</ErrorParagraph>
                        </Row>
                        <Row style={{ paddingBottom: 10 }}>
                            <Col>
                                <p>Completed on</p>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    defaultValue={
                                        education.end_date ? new Date(education.end_date) : null
                                    }
                                    render={({ field }) => (
                                        <DatePicker
                                            oneTap
                                            ranges={[]}
                                            format="MMM yyyy"
                                            limitEndYear={50}
                                            onSelect={date => handleRangeSelect(date)}
                                            onClean={() => setEndDate(undefined)}
                                            onEmptied={() => setEndDate(undefined)}
                                            {...field}
                                        />
                                    )}
                                />
                                <ErrorParagraph>{errors.end_date?.message}</ErrorParagraph>
                            </Col>
                        </Row>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                appearance="primary"
                                color="green"
                                type="submit"
                                loading={updateEducation.isLoading}
                            >
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Close</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={24}>
                        <p>
                            Description<span style={{ color: 'red' }}>*</span>
                        </p>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={education.description}
                            render={({ field }) => (
                                <Input size="lg" as="textarea" rows={6} {...field} />
                            )}
                        />
                        <ErrorParagraph>{errors.description?.message}</ErrorParagraph>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid justify="end" style={{ paddingTop: 20, alignContent: 'center' }}>
                    <FlexboxGrid.Item>
                        <ConfirmationDialog
                            handleOK={() => handleDelete(education.id)}
                            header={'Delete Work Experience'}
                            message={'Are you sure you want to delete this work experience?'}
                        >
                            <Button
                                appearance="primary"
                                color="red"
                                loading={deleteEducation.isLoading}
                            >
                                Delete
                            </Button>
                        </ConfirmationDialog>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </form>
    );
};
