import NavigationBar from 'components/navigation/NavigationBar';
import {
    CircleDetailsScreen,
    CirclesScreen,
    CompanyScreen,
    ControlPanel,
    CustomerDetailsScreen,
    CustomerProjectDetailsScreen,
    CustomerProjectsScreen,
    CustomersScreen,
    HomeScreen,
    InternalProjectDetailsScreen,
    InternalProjectsScreen,
    InternalProjectTypeDetailsScreen,
    InternalProjectTypesScreen,
    LedgerScreen,
    LogHoursScreen,
    MembershipsScreen,
    MembersScreen,
    MyCareerScreen,
    MyProjectsScreen,
    MyTimeTrackScreen,
    NotFound,
    RevenueScreen,
    ExternalUsersScreen,
    SideNavigation,
    TimeTrackScreen,
    WeightEditScreen,
    WikiScreen,
} from 'components/screens';
import { SkillsScreen } from 'components/screens/skills';
import { WikiSheet } from 'components/screens/wiki/WikiSheet';
import { WikiSheetList } from 'components/screens/wiki/WikiSheetList';
import { WikiSheetPage } from 'components/screens/wiki/WikiSheetPage';
import { CSSProperties, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { Container, Content, Header, Panel, Sidebar } from 'rsuite';

const OPEN_WHEN_WIDER_THAN = 800;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300 * 1000,
        },
    },
});

const sidebarStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
};

export const App = () => {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const expanded = viewPortWidth >= OPEN_WHEN_WIDER_THAN;
    // https://stackoverflow.com/a/60327893

    const updateWindowDimensions = () => {
        setViewPortWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Container style={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <Header>
                    <NavigationBar />
                </Header>
                <Container>
                    <Sidebar style={sidebarStyle} width={expanded ? 225 : 60} collapsible>
                        <SideNavigation />
                    </Sidebar>
                    <Content>
                        <Panel>
                            <Routes>
                                <Route path="/" element={<HomeScreen />} />
                                <Route path="/circles" element={<CirclesScreen />} />
                                <Route path="/my/projects" element={<MyProjectsScreen />} />
                                <Route path="/weights" element={<WeightEditScreen />} />
                                <Route path="/circle/:id" element={<CircleDetailsScreen />} />
                                <Route path="/ledger" element={<LedgerScreen />} />
                                <Route path="/members" element={<MembersScreen />} />
                                <Route path="/memberships" element={<MembershipsScreen />} />
                                <Route path="/:userId/career" element={<MyCareerScreen />} />
                                <Route path="/resume" element={<SkillsScreen />} />
                                <Route
                                    path="/resume/search"
                                    element={<SkillsScreen searchMode={true} />}
                                />
                                <Route path="/resume/:userId" element={<SkillsScreen />} />
                                <Route
                                    path="/resume/preview"
                                    element={<SkillsScreen previewMode={true} />}
                                />
                                <Route
                                    path="/resume/:userId/preview/"
                                    element={<SkillsScreen previewMode={true} />}
                                />
                                <Route path="/my/timetracks" element={<MyTimeTrackScreen />} />
                                <Route path="/my/hours" element={<LogHoursScreen />} />
                                <Route path="/timetracks" element={<TimeTrackScreen />} />
                                <Route path="/revenue" element={<RevenueScreen />} />
                                <Route path="/customers" element={<CustomersScreen />} />
                                <Route
                                    path="/customers/:customerId"
                                    element={<CustomerDetailsScreen />}
                                />
                                <Route path="/company/*" element={<CompanyScreen />} />
                                <Route path="/control-panel/*" element={<ControlPanel />} />
                                <Route
                                    path="/internalprojects"
                                    element={<InternalProjectsScreen />}
                                />
                                <Route
                                    path="/internalprojects/:projectId"
                                    element={<InternalProjectDetailsScreen />}
                                />
                                <Route
                                    path="/customerprojects"
                                    element={<CustomerProjectsScreen />}
                                />
                                <Route
                                    path="/customerprojects/:projectId"
                                    element={<CustomerProjectDetailsScreen />}
                                />
                                <Route
                                    path="/internalprojecttypes"
                                    element={<InternalProjectTypesScreen />}
                                />
                                <Route
                                    path="/internalprojecttypes/:typeId"
                                    element={<InternalProjectTypeDetailsScreen />}
                                />
                                <Route path="/externalUsers" element={<ExternalUsersScreen />} />
                                <Route path="/wiki/create" element={<WikiSheet />} />
                                <Route path="/wiki/list" element={<WikiSheetList />} />
                                <Route path="/wiki/1" element={<WikiScreen />} />
                                <Route path="/wiki/:articleId" element={<WikiSheetPage />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Panel>
                    </Content>
                </Container>
            </Container>
        </QueryClientProvider>
    );
};
