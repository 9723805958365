import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { useEducationsHook } from 'hooks/skills/useEducations';
import { useExperiencesHook } from 'hooks/skills/useExperiences';
import { useMemberCertificationsHook } from 'hooks/skills/useMemberCertifications';
import { useMemberSkillsHook } from 'hooks/skills/useMemberSkills';
import { useExternalPicture, useSkillsMemberHook } from 'hooks/skills/useSkillsMember';
import { useStatusHook } from 'hooks/skills/useStatus';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Loader } from 'rsuite';

import { NotFound } from '../NotFound';
import { CVEditor } from './CVEditor';
import { HeaderPanel } from './HeaderPanel';
import { PdfCV } from './pdf-cv';
import { PdfViewer } from './PdfViewer';
import { isExtUserId } from './Profile';
import { SkillsSearch } from './search';
import { ToolBar } from './ToolBar';
import { isExternalRouter } from 'auth/cognito';

export const extSkillsIdAddition = 1000000;

export function SkillsScreen({
    previewMode = false,
    searchMode = false,
}: {
    previewMode?: boolean;
    searchMode?: boolean;
}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { userId } = useParams();
    const { user } = useProfile();
    const [selectedMemberId, setSelectedMemberId] = useState<number | undefined>();
    const [selectedSkills, setSelectedSkills] = useState<number[]>([]);
    const [selectedCertifications, setSelectedCertifications] = useState<number[]>([]);
    const [selectedMinLevel, setSelectedMinLevel] = useState<number>(0);
    const member_id =
        selectedMemberId && selectedMemberId !== user?.id ? selectedMemberId : user?.id;
    const useStatus = useStatusHook(isExternalRouter);
    const { isAvailable, isFetched: statusIsFetched } = useStatus();
    const useSkillsMembers = useSkillsMemberHook(isExternalRouter);
    const {
        skillsMember,
        isLoading: skillsMemberIsLoading,
        isFetched: skillsMemberIsFetched,
    } = useSkillsMembers({
        enabled: isAvailable,
        member_id,
    });
    const { skillsMemberPicture } = useExternalPicture({
        enabled: !!selectedMemberId && isExtUserId(selectedMemberId),
        member_id: selectedMemberId,
    });
    const useMemberSkills = useMemberSkillsHook(isExternalRouter);
    const { memberSkills, isLoading: skillsLoading } = useMemberSkills({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useExperiences = useExperiencesHook(isExternalRouter);
    const { experiences, isLoading: experiencesLoading } = useExperiences({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useEducations = useEducationsHook(isExternalRouter);
    const { educations, isLoading: educationsLoading } = useEducations({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });
    const useMemberCertifications = useMemberCertificationsHook(isExternalRouter);
    const { memberCertifications, isLoading: certificationsLoading } = useMemberCertifications({
        enabled: isAvailable && !!skillsMember,
        member_id,
    });

    const [circlesExperiences, otherExperiences] = experiences.reduce(
        (acc, experience) => {
            if (experience.company?.circles_customer_id || experience.is_internal_project) {
                acc[0].push(experience);
            } else {
                acc[1].push(experience);
            }
            return acc;
        },
        [[], []] as [SkillsExperienceModelList, SkillsExperienceModelList],
    );
    const skillsExist = selectedSkills.length > 0;
    const certificationsExist = selectedCertifications.length > 0;

    useEffect(() => {
        if (userId && !isNaN(Number(userId))) {
            setSelectedMemberId(Number(userId));
        } else {
            setSelectedMemberId(undefined);
        }
    }, [userId]);

    useEffect(() => {
        let navigatePath = '/resume';

        if (skillsExist || certificationsExist) {
            navigatePath += '/search?';

            if (skillsExist) {
                const skills = encodeURIComponent(selectedSkills.join(','));
                navigatePath += `skills=${skills}`;
            }
            if (certificationsExist) {
                if (skillsExist) {
                    navigatePath += '&';
                }
                const certifications = encodeURIComponent(selectedCertifications.join(','));
                navigatePath += `certifications=${certifications}`;
            }

            const minLevel =
                selectedMinLevel > 0 && skillsExist ? `&min_level=${selectedMinLevel}` : '';
            navigate(`${navigatePath}${minLevel}`);
        } else if (!userId && !previewMode) {
            navigate(navigatePath);
        }
    }, [selectedSkills, selectedCertifications, selectedMinLevel]);

    useEffect(() => {
        if (searchParams.has('skills')) {
            const skills = searchParams
                .get('skills')
                ?.split(',')
                .map(skill => Number(skill));
            if (skills) {
                setSelectedSkills(skills);
            }
        }

        if (searchParams.has('certifications')) {
            const certifications = searchParams
                .get('certifications')
                ?.split(',')
                .map(certification => Number(certification));
            if (certifications) {
                setSelectedCertifications(certifications);
            }
        }

        if (searchParams.has('min_level')) {
            const minLevel = searchParams.get('min_level');
            if (minLevel) {
                setSelectedMinLevel(Number(minLevel));
            }
        }
    }, [searchParams]);

    const editAllowed = user?.id === selectedMemberId || !selectedMemberId;
    const showSearch = (skillsExist || certificationsExist) && searchMode;
    return (
        <>
            <ToolBar
                setSelectedMemberId={setSelectedMemberId}
                selectedMemberId={selectedMemberId}
                selectedMinLevel={selectedMinLevel}
                setSelectedMinLevel={setSelectedMinLevel}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
                previewMode={previewMode}
                skillsEnabled={isAvailable}
                selectedCertifications={selectedCertifications}
                setSelectedCertifications={setSelectedCertifications}
            />
            {!isAvailable && statusIsFetched ? (
                <NotFound message="Skills DB service is not available" />
            ) : showSearch ? (
                <SkillsSearch
                    selectedSkills={selectedSkills}
                    selectedMinLevel={selectedMinLevel}
                    selectedCertifications={selectedCertifications}
                />
            ) : !skillsMember && skillsMemberIsFetched ? (
                <NotFound message="Member not found in Skills DB" />
            ) : skillsMemberIsLoading || !skillsMember ? (
                <Loader center content={'Resume is loading...'} />
            ) : (
                <>
                    <HeaderPanel
                        previewMode={previewMode}
                        document={
                            <PdfCV
                                circlesExperiences={circlesExperiences}
                                otherExperiences={otherExperiences}
                                skills={memberSkills}
                                certifications={memberCertifications}
                                member={skillsMember}
                                educations={educations}
                                externalPicture={skillsMemberPicture}
                            />
                        }
                        member={skillsMember}
                        selectedMemberId={selectedMemberId}
                    />
                    {previewMode ? (
                        <PdfViewer
                            circlesExperiences={circlesExperiences}
                            otherExperiences={otherExperiences}
                            skillsMember={skillsMember}
                            memberSkills={memberSkills}
                            certifications={memberCertifications}
                            educations={educations}
                            externalPicture={skillsMemberPicture}
                        />
                    ) : (
                        <CVEditor
                            editAllowed={editAllowed}
                            memberSkills={memberSkills}
                            skillsMember={skillsMember}
                            circlesExperiences={circlesExperiences}
                            otherExperiences={otherExperiences}
                            educations={educations}
                            experiencesLoading={experiencesLoading}
                            educationsLoading={educationsLoading}
                            skillsLoading={skillsLoading}
                            certificationsLoading={certificationsLoading}
                            memberCertifications={memberCertifications}
                        />
                    )}
                </>
            )}
        </>
    );
}
