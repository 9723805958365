import { EditExternalUserDialog } from 'components/dialogs/EditExternalUserDialog';
import { MoveExternalUserDialog } from 'components/dialogs/MoveUserDialog';
import { ExternalUserModel } from 'generated-types/external_user';
import { RefAttributes, useState } from 'react';
import { Table, TableProps } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

const { Column, HeaderCell, Cell } = Table;

type TableData = {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
};

type Props = {
    data: ExternalUserModel[];
    selectedUserId?: number;
};

export const ActionCell = (
    props: JSX.IntrinsicAttributes &
        InnerCellProps &
        RefAttributes<HTMLDivElement> & { selecteduserid?: number },
) => {
    return (
        <Cell {...props}>
            <EditExternalUserDialog
                externalUser={props.rowData}
                openDialog={
                    (props?.selecteduserid && props.selecteduserid === props.rowData.id) || false
                }
            />
        </Cell>
    );
};

export const MoveUserCell = (
    props: JSX.IntrinsicAttributes &
        InnerCellProps &
        RefAttributes<HTMLDivElement> & { selecteduserid?: number },
) => {
    return (
        <Cell {...props}>
            <MoveExternalUserDialog
                externalUser={props.rowData}
                openDialog={
                    (props?.selecteduserid && props.selecteduserid === props.rowData.id) || false
                }
            />
        </Cell>
    );
};

export default function ExternalUsersTable(props: Props) {
    const [sortType, setSortType] = useState<TableProps['sortType']>('asc');
    const [sortColumn, setSortColumn] = useState<keyof TableData>('lastname');

    const setSort = (sortColumn: keyof TableData) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = props.data.map(externalMember => {
            return {
                id: externalMember.id,
                name: `${externalMember.lastname} ${externalMember.firstname} `,
                email: externalMember.email,
                firstname: externalMember.firstname,
                lastname: externalMember.lastname,
            };
        });

        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    return (
        <Table
            autoHeight
            rowHeight={55}
            sortColumn={'name'}
            sortType={sortType}
            onSortColumn={setSort as (sortColumn: string) => void}
            data={getTableData()}
        >
            <Column align="left" fixed flexGrow={1} minWidth={160} sortable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
            </Column>
            <Column align="left" fixed flexGrow={1} minWidth={160} sortable>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="email" />
            </Column>
            <Column align="left" fixed flexGrow={1} minWidth={160}>
                <HeaderCell> </HeaderCell>
                <ActionCell align="center" selecteduserid={props?.selectedUserId} />
            </Column>
            <Column align="left" fixed flexGrow={1} minWidth={160}>
                <HeaderCell> </HeaderCell>
                <MoveUserCell align="center" selecteduserid={props?.selectedUserId} />
            </Column>
        </Table>
    );
}
